.info-block {
  display: block;
  margin: 15px var(--section-horizontal-gap) 10px var(--section-horizontal-gap);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 15px 15px 13px;
  color: var(--dark-color);
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--primary-color-light);
  border: none;
  text-align: left;
  font-size: rem(16);
  line-height: 1.3125;

  .neos-backend & {
    display: none !important;
  }
}

.info-block--ios-only {
  display: none;

  @supports (-webkit-touch-callout: none) {
    /* CSS for iOS devices */
    //display: block;
  }
}

.info-block--notification {
  @include link-icon-right;

  color: #fdfefc;
  background-color: #8ec86a;
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='25' viewBox='0 0 11 12.4'%3E%3Cg transform='translate(-581.774 -50.053)'%3E%3Cpath fill='white' d='M583.242,60.883A1.468,1.468,0,0,1,581.8,59.67a1.667,1.667,0,0,1,.412-1.422,2.231,2.231,0,0,0,.633-1.472l-.07-.684a3.966,3.966,0,0,1,7.812-1.377l.253.778a1.856,1.856,0,0,0,.982.938,1.686,1.686,0,0,1,1.07,1.283,1.468,1.468,0,0,1-1.191,1.7l-8.2,1.446A1.508,1.508,0,0,1,583.242,60.883Zm3.451-8.656a3.222,3.222,0,0,0-2.632,1.367,3.184,3.184,0,0,0-.53,2.394l.075.75a2.956,2.956,0,0,1-.829,2.024.906.906,0,0,0-.225.775.7.7,0,0,0,.815.571l8.2-1.446a.7.7,0,0,0,.571-.816.918.918,0,0,0-.584-.7,2.634,2.634,0,0,1-1.422-1.371l-.294-.9a3.2,3.2,0,0,0-3.147-2.648M584.967,52.517a.382.382,0,0,1-.376-.315,2.073,2.073,0,0,1,.205-1.355,1.425,1.425,0,0,1,2.263-.4,2.081,2.081,0,0,1,.656,1.2.382.382,0,0,1-.752.132,1.307,1.307,0,0,0-.407-.761.666.666,0,0,0-1.091.192,1.3,1.3,0,0,0-.122.855.381.381,0,0,1-.31.442A.369.369,0,0,1,584.967,52.517M585.908,62.131c-.5,0-1.284-.269-1.563-1.849a.382.382,0,0,1,.752-.133c.155.884.468,1.29.927,1.209a1.559,1.559,0,0,0,.873-.572,1.3,1.3,0,0,0,.31-1.009.382.382,0,1,1,.752-.132,2.027,2.027,0,0,1-.465,1.616,2.313,2.313,0,0,1-1.338.848A1.351,1.351,0,0,1,585.908,62.131Z'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: auto 30px;
  padding-left: 47px;
  padding-right: 35px;

  &:after {
    border-color: white;
  }
}

.info-blocks .cc-revoke,
.info-blocks .cc-window {
  @extend .info-block;
  position: relative;
  margin: 15px var(--section-horizontal-gap) 10px var(--section-horizontal-gap);
}
