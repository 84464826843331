.merchandise-shop {
    &__hint {
        font-size: 14px;
        color: #666666;
    }

    &__images {
        padding-top: var(--section-vertical-gap);
        column-count: 2;
        column-gap: 10px;
        margin-bottom: 10px;

        & figure {
            margin: 0;
            display: grid;
            grid-template-rows: 1fr auto;
            margin-bottom: 10px;
            break-inside: avoid;

            & > img {
                max-width: 100%;
                display: block;
                grid-row: 1 / -1;
                grid-column: 1;
            }
        }
    }

    &__motives {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 20px;
    }

    &__motive {
        text-align: center;
        cursor: pointer;

        & * {
            pointer-events: none;
        }

        &__image {
            display: flex;
            width: 100%;
            height: 200px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 5px solid #FFFFFF;
            border-radius: 7px;
            transition: border .3s ease;
            padding: 1rem;

            & img {
                margin: auto;
                width: 100%;
                max-width: 100%;
                max-height: 100%;
            }
        }

        &__label {
            display: block;
            margin-top: 11px;
            color: #666666;
        }

        &.merchandise-shop__spec-item--selected {
            .merchandise-shop__motive__image {
                border: 5px solid #C8E3B8;
            }
        }
    }

    &__pills {
         padding-bottom: var(--section-vertical-gap);

        &__item {
            border-radius: 11px;
            background-color: #FFFFFF;
            margin: 0 .5rem .5rem 0;
            padding: .5rem;
            color: #666666;
            display: inline-block;
            text-decoration: none !important;
            font-size: 14px;
            transition: all .3s ease;
            box-shadow: 0 3px 6px #00000029;
            cursor: pointer;
            min-width: 70px;
            text-align: center;

            &.merchandise-shop__spec-item--selected {
                background-color: #C8E3B8;
                box-shadow: none;
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;

        input[type='text'],
        input[type='email'],
        input[type='date'],
        select {
            font-family: 'DM Sans', sans-serif;
            padding: .5rem;
            margin-bottom: .25rem;
            font-size: 16px;
        }

        input[type='date'] {
            width: 100%;
        }

        select {
            background-color: #fff;
            padding: 0.375rem 0.5rem;
            border: none;
            outline: none !important;
        }

        ::placeholder {
            color: #272726 !important;
        }

        ::-webkit-input-placeholder {
            color: #272726 !important;
        }

        &__submit-button {
            font-weight: bold;
            font-family: 'DM Sans', sans-serif;
            border: 0 none;
            background: var(--primary-color);
            color: var(--header-background);
            font-size: 1.25rem;
            padding: .5rem;
            margin-bottom: 2rem;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
            transition: all .3s ease;

            &[disabled] {
                background: var(--primary-color-light);
                box-shadow: 0 0 0 0 !important;
            }
        }
    }
}
