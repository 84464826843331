:root {
  --h1: rem(48);
  --h2: rem(40);
  --h3: rem(32);
  --h4: rem(24);
  --h5: rem(18);
  --h6: rem(16);
  --p: rem(14);
  --subtitle: rem(14);
}

h1, .h1 {
  font-size: var(--h1);
}

h2, .h2 {
  font-size: var(--h2);
}

h3, .h3 {
  font-size: var(--h3);
}

h4, .h4 {
  font-size: var(--h4);
}

h5, .h5 {
  font-size: var(--h5);
}

h6, .h6 {
  font-size: var(--h6);
}

.subtitle {
  font-size: var(--subtitle);
  line-height: 1.2;
}

p, .p {
  font-size: var(--p);
  line-height: 1.4;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

p.suptitle {
  font-size: var(--suptitle);
}

p.quote {
  font-style: italic;
  padding: 0 15px;
}

small {
  font-size: var(--subtitle);
}

mark {
  background: var(--primary-color-o30);
}
