:root {
  --footer-height: 70px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: var(--safe-area-inset-bottom);
  width: 100%;
  z-index: 100;
  opacity: 1;
  transform: translate(0, 0);
  background: linear-gradient(to bottom, $primary 0%, #57893a 100%);

  @include high-contrast {
    background: #57893a;
    color: #000;
  }
}

.navigation {
  ul {
    list-style: none;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--section-horizontal-gap);

    @media screen and (min-width: 600px) {
      justify-content: center;
    }

    li {
      width: 100%;
      opacity: 1;
      max-width: 120px;

      &.active a {
        opacity: 1;
        box-shadow: 0 -4px 0 0 $white inset;

        @include high-contrast {
          box-shadow: 0 -4px 0 0 $black inset;
        }
      }
    }
  }

  a {
    outline: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: var(--footer-height);
    width: 100%;
    fill: $white;
    color: $white;
    opacity: .45;
    font-size: rem(11);
    line-height: 1;
    background-color: transparent;
    transition: .2s opacity ease-in-out, .2s background-color ease-in-out;

    @include active {
      opacity: 1;
      background-color: rgba(0, 0, 0, .025);
    }

    @include high-contrast {
      opacity: 1;
      color: $black;
      fill: $black;
    }

    svg {
      margin-bottom: 5px;
    }
  }
}
