.splash {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNzUiIGhlaWdodD0iNjYzIiB2aWV3Qm94PSIwIDAgNzc3IDY2NiI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMDAgLTM1NSkgc2NhbGUoMyAyLjA3KSI+CiAgICA8cGF0aCBmaWxsPSIjYzhlM2I4IiBkPSJNMzcuNDEgNjY1LjI2aDY2NS42ODdWMEgzNy40MTF6Ii8+CiAgICA8cGF0aCBmaWxsPSIjYWNkNTk1IiBkPSJNNzM5LjI4MyA2NjUuMjYxSDczLjU5NnYtNDc3LjIybDY2NS42ODctODEuODkyeiIvPgogICAgPHBhdGggZmlsbD0iIzkxYzc3MSIgZD0iTTY2NS42ODcgNjY1LjI2MUgwVjI1OC43NzVsNjY1LjY4NyAyOS43ODh6Ii8+CiAgICA8cGF0aCBmaWxsPSIjNzViOTRlIiBkPSJNNzc2LjA4IDY2NS4yNjFIMTEwLjM5NVY1MTUuNjYzTDc3Ni4wOCAzMzIuNjN6Ii8+CiAgPC9nPgo8L3N2Zz4K");
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  padding: 44px 72px;
  pointer-events: none;
  overflow: hidden;

  &__logo {
    display: block;
    width: 100%;
    max-width: 222px;
    height: auto;
    margin-bottom: 44px;
  }

  p {
    color: $white;
    font-size: rem(14);
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
  }
}
