:root {
  --section-horizontal-gap: 12px;
  --negative-section-horizontal-gap: -12px;
  --section-vertical-gap: 20px;
}

html {
  margin: 0;
  padding: 0;
}

body {
  padding: 0 0 var(--footer-height);
  margin: 0;
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: stretch;
  overflow-x: hidden;
  will-change: opacity;
  transition: .3s opacity ease-in-out;
  background-color: var(--body-background);
  font-family: $mdsans;
  word-wrap: break-word;

  header + main > *:first-child {
    margin-top: var(--header-height) !important;
  }

  @include selection(darken(#75b94e, 25%), $white);

  @media (prefers-color-scheme: dark) {
    @include selection(lighten(#75b94e, 25%), $white);
  }

  &.body--prevent-scroll {
    overflow: hidden;
  }
}

main {
  width: 100%;
  flex-grow: 1;
  align-items: stretch;
  justify-content: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: 20;
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: var(--body-background);
  padding: var(--section-vertical-gap) var(--section-horizontal-gap);

  &:last-child {
    margin-bottom: 0;
  }
}

.container {
  max-width: 600px;
  position: relative;
  margin: 0 auto;
}

.page-title {
  color: var(--secondary-color);
  letter-spacing: -0.72px;
  margin-bottom: 0;
  padding: 20px var(--section-horizontal-gap) 0;
  background-color: var(--body-background);
  font-size: rem(24);
  font-weight: 700;


  .breadcrumbs + & {
    margin-top: -22px;
  }
}
