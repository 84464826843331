.tile__list {
  padding: 0 var(--section-horizontal-gap) 40px var(--section-horizontal-gap);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: var(--divider-height);
    background: var(--divider-color);
  }

  &:last-child:after {
    display: none;
  }

  &-title {
    font-size: rem(16);
  }

  ul {
    padding: 0;
    margin: -5px;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: stretch;
  }

  li {
    display: flex;
    max-width: 50%;
    width: 100%;
    padding: 5px;
    min-height: 110px;

    .tile__list-item {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      display: flex;
      width: 100%;
      padding: 8px 10px 6px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      color: var(--dark-color);
      text-decoration: none;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      background-color: var(--header-background);

      @include high-contrast {
        box-shadow: 0 0 0 2px $black inset;
      }

      @include active {
        background: var(--primary-color-o30);
      }

      &.primary {
        background-color: var(--primary-color-light);
      }

      &.secondary {
        background-color: var(--grey-light-color);
      }

      span {
        font-size: rem(14);
        font-weight: 700;
        display: block;
        width: 100%;
        white-space: normal;
        word-break: break-word;
      }

      small {
        font-size: rem(11);
        font-weight: 400;
        display: block;
      }
    }
  }
}
