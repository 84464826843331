:root {
  --header-height: calc(43px + var(--safe-area-inset-top));
}

.header {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: var(--safe-area-inset-top);
  height: var(--header-height);
  width: 100%;
  z-index: 40;
  background-color: var(--header-background);
  font-size: 0;

  .container {
    padding: 0 50px 0 var(--section-horizontal-gap);
  }

  &__logo {
    display: inline-block;
    overflow: hidden;
    padding: 14px 0 7px;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    text-decoration: none;

    @include active {
      .wir path {
        fill: darken(#649e43, 20%);
      }

      .mobile {
        fill: darken($primary, 20%);
      }
    }

    svg {
      height: 22px;
      width: auto;
      pointer-events: none;
      display: block;

      .wir {
        fill: #649e43;
        transition: .2s fill ease-in-out;
      }

      .mobile {
        fill: $primary;
        transition: .2s fill ease-in-out;
      }
    }
  }

  .notifications-bell, .header__search-modal-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    z-index: 10;
    border: none;
    box-shadow: none;
    background-position: 13px 50%;
    background-repeat: no-repeat;
    background-size: 22px 25px;
  }

  .header__search-modal-button {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNSAyNSI+DQogIDxkZWZzPg0KICAgIDxzdHlsZT4NCiAgICAgIC5jbHMtMSB7DQogICAgICAgIGZpbGw6ICM4M2I2NWM7DQogICAgICB9DQogICAgPC9zdHlsZT4NCiAgPC9kZWZzPg0KICA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMi42OCwxOS4zMSwxNywxMy42MUE4LjE3LDguMTcsMCwxLDAsMTMuNjEsMTdsNS43LDUuN2EyLjM3LDIuMzcsMCwwLDAsMS42OC42OSwyLjM4LDIuMzgsMCwwLDAsMS42OS00LjA2Wk01LjA3LDE0LjQ5YTYuNjYsNi42NiwwLDEsMSw5LjQyLDBBNi42OCw2LjY4LDAsMCwxLDUuMDcsMTQuNDlabTE2LjU0LDcuMTJhLjg2Ljg2LDAsMCwxLTEuMjQsMGwtNS4yNS01LjI0LDEuMjUtMS4yNSw1LjI0LDUuMjVBLjg2Ljg2LDAsMCwxLDIxLjYxLDIxLjYxWiIvPg0KPC9zdmc+DQo=");
    right: 40px;
    // increase clickable area
    width: 60px;
    background-position: 23px 50%;
  }

  .notifications-bell {
    padding-right: var(--section-horizontal-gap);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgd2lkdGg9IjIyIiBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMTEgMTIuNCI+CiAgPGcgZmlsbD0iIzE2MTYxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU4MS43NzQgLTUwLjA1MykiPgogICAgPHBhdGgKICAgICAgZmlsbD0iIzc1Yjk0ZSIKICAgICAgZD0iTTU4My4yNDIsNjAuODgzQTEuNDY4LDEuNDY4LDAsMCwxLDU4MS44LDU5LjY3YTEuNjY3LDEuNjY3LDAsMCwxLC40MTItMS40MjIsMi4yMzEsMi4yMzEsMCwwLDAsLjYzMy0xLjQ3MmwtLjA3LS42ODRhMy45NjYsMy45NjYsMCwwLDEsNy44MTItMS4zNzdsLjI1My43NzhhMS44NTYsMS44NTYsMCwwLDAsLjk4Mi45MzgsMS42ODYsMS42ODYsMCwwLDEsMS4wNywxLjI4MywxLjQ2OCwxLjQ2OCwwLDAsMS0xLjE5MSwxLjdsLTguMiwxLjQ0NkExLjUwOCwxLjUwOCwwLDAsMSw1ODMuMjQyLDYwLjg4M1ptMy40NTEtOC42NTZhMy4yMjIsMy4yMjIsMCwwLDAtMi42MzIsMS4zNjcsMy4xODQsMy4xODQsMCwwLDAtLjUzLDIuMzk0bC4wNzUuNzVhMi45NTYsMi45NTYsMCwwLDEtLjgyOSwyLjAyNC45MDYuOTA2LDAsMCwwLS4yMjUuNzc1LjcuNywwLDAsMCwuODE1LjU3MWw4LjItMS40NDZhLjcuNywwLDAsMCwuNTcxLS44MTYuOTE4LjkxOCwwLDAsMC0uNTg0LS43LDIuNjM0LDIuNjM0LDAsMCwxLTEuNDIyLTEuMzcxbC0uMjk0LS45YTMuMiwzLjIsMCwwLDAtMy4xNDctMi42NDhNNTg0Ljk2Nyw1Mi41MTdhLjM4Mi4zODIsMCwwLDEtLjM3Ni0uMzE1LDIuMDczLDIuMDczLDAsMCwxLC4yMDUtMS4zNTUsMS40MjUsMS40MjUsMCwwLDEsMi4yNjMtLjQsMi4wODEsMi4wODEsMCwwLDEsLjY1NiwxLjIuMzgyLjM4MiwwLDAsMS0uNzUyLjEzMiwxLjMwNywxLjMwNywwLDAsMC0uNDA3LS43NjEuNjY2LjY2NiwwLDAsMC0xLjA5MS4xOTIsMS4zLDEuMywwLDAsMC0uMTIyLjg1NS4zODEuMzgxLDAsMCwxLS4zMS40NDJBLjM2OS4zNjksMCwwLDEsNTg0Ljk2Nyw1Mi41MTdNNTg1LjkwOCw2Mi4xMzFjLS41LDAtMS4yODQtLjI2OS0xLjU2My0xLjg0OWEuMzgyLjM4MiwwLDAsMSwuNzUyLS4xMzNjLjE1NS44ODQuNDY4LDEuMjkuOTI3LDEuMjA5YTEuNTU5LDEuNTU5LDAsMCwwLC44NzMtLjU3MiwxLjMsMS4zLDAsMCwwLC4zMS0xLjAwOS4zODIuMzgyLDAsMSwxLC43NTItLjEzMiwyLjAyNywyLjAyNywwLDAsMS0uNDY1LDEuNjE2LDIuMzEzLDIuMzEzLDAsMCwxLTEuMzM4Ljg0OEExLjM1MSwxLjM1MSwwLDAsMSw1ODUuOTA4LDYyLjEzMVoiLz4KICA8L2c+Cjwvc3ZnPgo=");

    &.active {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDIyIDI1Ij4KICA8cGF0aAogICAgZmlsbD0iIzc1Yjk0ZSIKICAgIGQ9Ik0yLjg5IDIxLjc4YTIuODg5IDIuODg5IDAgMCAxLTIuODM5LTIuMzg0IDMuMjc3IDMuMjc3IDAgMCAxIC44MS0yLjc5NGMuNTY4LS42MjQgMS4yNDUtMS42MzIgMS4yNDQtMi44OTJsLS4xMzctMS4zNDRDMS4yMyA4LjE4OCA0LjA2NSA0LjEzNiA4LjMgMy4zOWM0LjIxMy0uNzQzIDguMjUxIDIuMDY2IDkuMDIyIDYuMjdsLjQ5NyAxLjUyN2MuNDE4Ljg1NSAxLjExNiAxLjU0MyAxLjkyOCAxLjg0NWEzLjMxNSAzLjMxNSAwIDAgMSAyLjEwNCAyLjUyMSAyLjg3IDIuODcgMCAwIDEtLjQ3OSAyLjE1NSAyLjg2NCAyLjg2NCAwIDAgMS0xLjg2IDEuMTg2TDMuMzkxIDIxLjczNmEyLjk1MyAyLjk1MyAwIDAgMS0uNTAyLjA0NE02LjI3NCA1LjM0YS43NS43NSAwIDAgMS0uNzM4LS42MmMtLjE2Ni0uOTQyLS4wMjMtMS44ODguNDAyLTIuNjYzQzYuMzk0IDEuMjI4IDcuMTEzLjY5IDcuOTY0LjU0YzEuNjk3LS4yOTQgMy4zNTcgMS4wOTIgMy43MTIgMy4xYS43NS43NSAwIDAgMS0xLjQ3OC4yNmMtLjIwNy0xLjE3My0xLjEwNS0yLjAzMy0xLjk3My0xLjg4My0uMzk1LjA3LS43NC4zNDEtLjk3Mi43NjMtLjI2LjQ3Ny0uMzQ3IDEuMDczLS4yMzkgMS42OGEuNzUxLjc1MSAwIDAgMS0uNzQuODgxTTUuNjY4IDIyLjg1NmMuNzEgMS4zODcgMS43MzMgMS42NzcgMi40NTcgMS42NzcuMTg2IDAgLjM1Mi0uMDE5LjQ4OC0uMDQzIDEuMzA2LS4yMyAyLjYzLTEuMzM2IDMuMjM4LTIuNzI0eiIvPgo8L3N2Zz4K");
    }

    &__counter {
      display: block;
      border-radius: 100%;
      position: absolute;
      top: 7px;
      right: 11px;
      padding: 0 1px;
      line-height: 12px;
      min-width: 12px;
      min-height: 12px;
      text-align: center;
      vertical-align: center;
      background-color: $danger;
      color: $white;
      box-shadow: 0 0 0 1px var(--header-background);
      font-size: rem(8);
      z-index: 3;
      font-weight: 700;
      pointer-events: none;
      user-select: none;
    }
  }

  .link-back {
    display: inline-block;
    color: var(--primary-color);
    font-size: rem(16);
    font-weight: 700;
    line-height: 1;
    padding: 14px var(--section-horizontal-gap) 7px;
    text-decoration: none;
    margin-left: var(--negative-section-horizontal-gap);
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
    opacity: .7;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}
