.contact__list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;

  li {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background: var(--header-background);
    margin-bottom: 10px;

    & > a {
      outline: none;
      text-decoration: none;
      position: relative;
      padding: 20px 10px;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
    }

    .avatar {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      margin-right: 10px;
    }

    .name {
      font-weight: 700;
      font-size: rem(16);
      color: var(--dark-color);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;

      small {
        font-size: rem(11);
        color: var(--body-text-color)666;
        display: block;
      }
    }

    object {
      display: flex;
      margin: 0 0 0 auto;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: #e3f1dc;
        border-radius: 100%;
        margin-left: 10px;

        img {
          display: block;
          width: 22px;
          height: auto;
        }
      }
    }

    sub {
      position: absolute;
      bottom: 5px;
      right: 5px;
      letter-spacing: 1.1px;
      text-transform: uppercase;
      font-weight: 700;
      color: #9b9b9b;
      font-size: rem(11);
    }
  }
}
