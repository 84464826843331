:root {
  --divider-height: 8px;
  --negative-divider-height: -8px;
}

.article {
  padding: var(--section-vertical-gap) var(--section-horizontal-gap);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: var(--divider-height);
    background: var(--divider-color);
  }

  &:last-child:after {
    display: none;
  }

  &__author-and-share {
    // align author and share
    position: relative;
  }

  &__author {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 20px;
    padding-right: 50px;
    z-index: 3;
    text-decoration: none;

    &-avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      object-fit: contain;
    }

    &-name {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 3px 0;

      span {
        color: var(--primary-color);
        margin: 0 0 2px 0;
        font-size: rem(14);
        font-weight: 700;
      }

      small {
        color: #989898;
        font-size: rem(11);
      }
    }
  }

  &__share {
    position: absolute;
    top: 0;
    right: var(--section-horizontal-gap);
    z-index: 4;
    width: 40px;
    height: 40px;
    padding: 10px 14px;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @include active {
      background-color: var(--primary-color-o30);
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  // content types
  &__content {
    &-image {
      height: auto;
      margin: 0 var(--negative-section-horizontal-gap);
      position: relative;
      z-index: 10;
      pointer-events: none;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-slider {
      overflow: hidden;
      height: auto;
      margin: 0 var(--negative-section-horizontal-gap);
      position: relative;
      z-index: 10;
    }

    &-video {
      position: relative;
      overflow: hidden;
      margin: 0 var(--negative-section-horizontal-gap) 20px;
      z-index: 10;

      &:after {
        content: '';
        position: relative;
        padding-bottom: 56.25%;
        width: 100%;
        height: 0;
        display: block;
        pointer-events: none;
      }

      iframe {
        position: absolute;
        top: -100%;
        width: 100%;
        height: 300%;
      }
    }
  }

  // article main link
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: var(--divider-height);
    background: transparent;
    outline: none !important;
    -webkit-tap-highlight-color: var(--primary-color-o30);
    z-index: 2;
  }

  &__ad {
    background: var(--divider-color);
    padding-top: var(--section-vertical-gap);
    padding-bottom: var(--section-vertical-gap);

    &--30x5 .ad {
      width: 300px;
      height: 50px;
    }

    &--30x25 .ad {
      width: 300px;
      height: 250px;
    }

    &--30x60 .ad {
      width: 300px;
      height: 600px;
    }

    .ad {
      display: block;
      position: relative;
      margin: 0 auto;
      background: $white;

      span {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2px 7px;
        background: var(--primary-color);
        color: $white;
        font-size: rem(11);
      }

      img,
      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
  }

  & + .article__ad {
    margin-top: var(--negative-divider-height);
  }
}

.link-box {
  display: block;
  border: 1px solid #ccc;
  position: relative;
  width: 100%;
  height: auto;
  padding: 10px 115px 10px 10px;
  min-height: 70px;
  text-decoration: none;
  pointer-events: auto;
  z-index: 10;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;

  @include active {
    border-color: var(--primary-color);
    background-color: var(--primary-color-o30);

    .link-box__button {
      color: $white;
      background-color: var(--primary-color);
    }
  }

  &__content {
    small {
      display: block;
      color: #989898;
      font-size: rem(11);
      margin-bottom: 10px;
    }

    strong {
      font-weight: 400;
      font-size: rem(14);
      line-height: 1.5;
      display: block;
      color: var(--dark-color);
    }
  }

  &__button {
    font-size: rem(13);
    display: block;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 8px 10px;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    text-decoration: none;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }
}


.article__content {
  color: var(--dark-color);
  margin: 0 0 20px;
  pointer-events: none;
  position: relative;
  z-index: 10;

  &-title {
    font-weight: 700;
    margin: 0 0 10px;
  }

  h3.article__content-title {
    font-size: rem(16);
    line-height: 1.3125;
  }

  h2.article__content-title {
    font-size: rem(21);
    line-height: 1.2;
  }

  h1.article__content-title {
    font-size: rem(24);
    line-height: 1.2;
  }

  h1.article__content-title {
    font-size: rem(24);
    line-height: 1.1666;
  }
}

.betterembed {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  margin: 0;
  max-width: 100%;
  width: 100%;
  padding: var(--section-vertical-gap) var(--section-horizontal-gap);
  color: #989898;
  font-size: .875rem;
  border: 1px solid #ccc;

  &__top {
    order: 3;
  }

  &__network,
  &-switch {
    font-size: 0.6875rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 2px;
    color: var(--body-text-color);
  }

  &__header {
    order: 2;
    margin: 0 0 11px !important;

    h2 {
      font-size: 1rem;
      line-height: 1.3125;
      font-weight: 700;
      margin: 0;
    }
  }

  &__text {
    order: 3;
    font-size: var(--p);
    line-height: 1.4;
    margin-bottom: 15px !important;
  }

  &__body {
    background: var(--body-background);
    color: var(--dark-color);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    & > :last-child {
      padding: 0;
    }
  }

  &__body > :not(.betterembed__media) {
    margin: 0;
  }

  &__media,
  &__media-link {
    order: 4;
    margin: 0;
  }

  &__footer {
    display: none;
    /*
    right now we do not want to show the link author and publish date,
    proper styling for this below:
    order: 1;
    border-top: none;
    margin-bottom: 15px !important;

    .betterembed__author {
      font-size: 0.875rem;
      font-weight: 700;
      color: var(--primary-color);
      text-decoration: none !important;
      transition: .2s color ease-in-out;

      &:hover {
        color: var(--primary-color-dark) !important;
      }

      @include active {
        color: var(--primary-color-dark) !important;
      }
    }
    */
  }

  &__read-more {
    float: right;
    font-size: rem(13);
    display: block;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
    margin-top: 5px;
    padding: 8px 10px;
    border-radius: 2px;
    text-decoration: none !important;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }
}
