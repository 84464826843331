
// better Neos empty view
.neos-backend .main-contentcollection:empty {
  background: var(--header-background);

  &:before {
    display: block;
    content: 'Inhalt hier hinzuf\00FCgen';
    font-size: 2rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
    text-align: center;
  }
}
