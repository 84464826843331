// fonts
$mdsans: "DMSans", sans-serif;

// paths
$path-font: '../fonts';
$path-img: '../images';
$path-static: '../../static';

// colors
$white: #fff;
$black: #000;
$grey: #484848;
$grey-light: #DDDDDD;
$silent: rgba($white, .2);

$success: #75b94e;
$warning: #ee8300;
$danger: #b94e4e;
$dark: #272726;
$secondary: #aaa;
$primary: $success;
$light: #EEEEEE;

// states
$state-active: '&:hover, &:focus, &:active, &:active:hover, &:active:focus';

@mixin high-contrast {
  @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    @content;
  }
}

:root {
  --primary-color: #75b94e;
  --primary-color-o10: rgba(#75b94e, .1);
  --primary-color-o20: rgba(#75b94e, .2);
  --primary-color-o30: rgba(#75b94e, .3);
  --primary-color-d15: darken(#75b94e, 15%);
  --primary-color-d20: darken(#75b94e, 20%);
  --primary-color-d25: darken(#75b94e, 25%);
  --primary-color-l20: lighten(#75b94e, 20%);
  --primary-color-light: #c8e3b8;
  --primary-color-dark: #315a19;
  --secondary-color: #aaa;
  --dark-color: #272726;
  --light-color: #272726;
  --header-background: #fff;
  --body-background: #f4f4f4;
  --body-text-color: #666;
  --grey-color: #e9e9e9;
  --grey-light-color: #ddd;
  --divider-color: linear-gradient(to right, #dddddd 0%, #cccccc 100%);
  --safe-area-inset-top: 0;
  --safe-area-inset-bottom: 0;
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);

  /*
  @media (prefers-color-scheme: dark) {
    --primary-color: #75b94e;
    --primary-color-o10: rgba(#75b94e, .1);
    --primary-color-o20: rgba(#75b94e, .2);
    --primary-color-o30: rgba(#75b94e, .3);
    --primary-color-d15: darken(#75b94e, 15%);
    --primary-color-d20: darken(#75b94e, 20%);
    --primary-color-d25: darken(#75b94e, 25%);
    --primary-color-l20: lighten(#75b94e, 20%);
    --primary-color-light: #315a19;
    --primary-color-dark: #c8e3b8;
    --secondary-color: #aaa;
    --dark-color: #fff;
    --light-color: #fff;
    --header-background: #333;
    --body-background: #262626;
    --body-text-color: #cecece;
    --grey-color: #333;
    --grey-light-color: #181818;
    --divider-color: linear-gradient(to right, #2a2a2a 0%, #2f2f2f 100%);
  }
  */

  @include high-contrast {
    --primary-color: #75b94e;
    --primary-color-o10: #75b94e;
    --primary-color-o20: #75b94e;
    --primary-color-o30: #75b94e;
    --primary-color-d15: #fff;
    --primary-color-d20: #fff;
    --primary-color-d25: #fff;
    --primary-color-l20: #000;
    --primary-color-light: #75b94e;
    --secondary-color: #000;
    --dark-color: #000;
    --light-color: #000;
    --header-background: #fff;
    --body-background: #fff;
    --body-text-color: #000;
    --grey-color: #eee;
    --grey-light-color: #fff;
    --divider-color: linear-gradient(to top, #000 8px, #fff 8px, #fff 100%);
  }
}
