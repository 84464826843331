.install-prompt {
  flex-direction: column;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10090;
  padding: 0 15px;
  background-color: #E3F1DC;

  h1 {
    font-size: 24px;
    letter-spacing: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 14px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10091;
    padding: 18px 15px;
    text-decoration: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    border: none;
    font-size: rem(16);
    background: transparent;
  }

  &__arrow-down {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 20px auto;
    animation-name: arrow-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  &--is-hidden {
    display: none;
  }
}


@keyframes arrow-animation {
  0% {
    transform: translateY(5px)
  }
  50% {
    transform: translateY(-3px)
  }
  to {
    transform: translateY(5px)
  }
}
