@mixin fonts($font-name: DMSans, $font-weight: 400, $isItalic: false) {
  $suffix: 'Regular' !default;

  @if ($font-weight == 100) {
    $suffix: 'Thin';
  } @else if ($font-weight == 200) {
    $suffix: 'ExtraLight';
  } @else if ($font-weight == 300) {
    $suffix: 'Light';
  } @else if ($font-weight == 500) {
    $suffix: 'Medium';
  } @else if ($font-weight == 600) {
    $suffix: 'SemiBold';
  } @else if ($font-weight == 700) {
    $suffix: 'Bold';
  } @else if ($font-weight == 800) {
    $suffix: 'Black';
  } @else if ($font-weight == 900) {
    $suffix: 'ExtraBold';
  }

  $font-normal-path: #{$path-font + '/' + $font-name + '-' + $suffix};

  @font-face {
    font-family: quote($font-name);
    src:  url('#{$font-normal-path}.woff') format('woff');
    font-weight: #{$font-weight};
    font-style: normal;
  }


  @if ($isItalic) {
    $font-italic-path: #{$path-font + '/' + $font-name + '-' + $suffix + 'Italic'};

    @font-face {
      font-family: quote($font-name);
      src:  url('#{$font-italic-path}.woff') format('woff');
      font-weight: #{$font-weight};
      font-style: italic;
    }
  }
}

@include fonts(DMSans, 700, true);
@include fonts(DMSans, 500, true);
@include fonts(DMSans, 400, true);
