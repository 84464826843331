
// myLPA specific survey styles
.codeq-survey-form {
  justify-content: flex-end;
}

.codeq-survey-form__rating-label {
  /* should be clickable with a thumb */
  font-size: 2rem;
  margin-right: 0.5rem;
}
