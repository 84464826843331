.search {
  &__number-of-entries {
    margin: 20px var(--section-horizontal-gap) 0;
  }

  &__form {
    margin: 20px var(--section-horizontal-gap) 0;

    & input[type='search'] {
      width: 100%;
      padding: 12px;
      font-size: 14px;
      border: 1px solid var(--secondary-color);
      border-radius: 4px;
    }
  }

  &__item {
    position: relative;
    padding: calc(40px - var(--divider-height)) 0 40px 4px;

    &-parents .breadcrumbs {
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      white-space: nowrap;
      padding: 0 !important;
      margin: 0 !important;
    }

    &-parents .breadcrumbs--ellipsis:after {
      content: '...';
      position: absolute;
      right: 0;
      height: 1.25em;
      background: linear-gradient(to right, transparent 0%, var(--body-background) 80%);
      color: #4d4d4d;
      padding-left: 30px;
    }

    & h3 {
      margin-top: 0;
      margin-bottom: .5rem;
    }

    & h3 svg {
      vertical-align: bottom;
      margin-right: 10px;
      fill: var(--primary-color);
    }

    & p {
      margin-top: .5rem;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 100%;
      height: var(--divider-height);
      background: var(--divider-color);
    }

    & em {
      background-color: yellow;
    }
  }
}


.header__search-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: .3s opacity ease-in-out;

  .container {
    background: $white;
    box-shadow: 0 0 0 100vh rgba(0, 0, 0, .2), 0 0 20px rgba(0, 0, 0, .2);
  }

  &--active{
    opacity: 1;
    pointer-events: auto;
  }

  .search {
    &__form {
      margin: 0;
      padding: 17px var(--section-horizontal-gap);
    }
  }
}

.search__form__suggestions {
  z-index: 999;
  background: #fcfcfc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid var(--secondary-color);
  border-top: none;
  margin-top: -4px;
  list-style: none;
  padding: 4px 0 0 0;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: $white;
  }

  li {
    padding: 12px;
    cursor: pointer;
    user-select: none;
    transition: .2s background ease-in-out, .2s color ease-in-out;

    &:hover,
    &:active,
    &:focus {
      background: var(--primary-color-light);
    }
  }

  .selected {
    color: $white;
    background: var(--primary-color);

    &:hover,
    &:active,
    &:focus {
      background: var(--primary-color-light);
    }
  }
}
