.better-embed-placeholder {
  padding: 1em;
  border: 0.3em #f15a24 solid;
  position: relative;
  margin: 2em 1em;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    background: #fff;
  }

  &::before {
    top: -0.3em;
    bottom: -0.3em;
    left: 1em;
    right: 1em;
  }

  &::after {
    top: 1em;
    bottom: 1em;
    left: -0.3em;
    right: -0.3em;
  }
}

.better-embed-placeholder__heading {
  position: relative;
  z-index: 1;
  font-size: 1.5em;
  text-align: center;
  font-family: sans-serif;
  line-height: 1.15;
}
