:root {
  --columns-gap: 10px;
  --double-columns-gap: 20px;
}

.article-inner {
  position: relative;
  padding: 0 var(--section-horizontal-gap) var(--section-vertical-gap) var(--section-horizontal-gap);

  .article__content {
    pointer-events: auto;
  }

  .article__content-image {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .article__author {
    padding-top: var(--section-vertical-gap);
  }
  .article__share {
    top: var(--section-vertical-gap);
  }

  .article__content-text figure:not(.table) {
    margin: var(--section-vertical-gap) var(--negative-section-horizontal-gap);

    figcaption {
      padding: 0 var(--section-horizontal-gap);
      font-size: rem(11);
      color: #989898;
    }

    img {
      width: 100%;
      height: auto;
      margin: 0 0 10px 0;
      display: block;
    }
  }

  .article__ad {
    width: auto;
    margin: 0 var(--negative-section-horizontal-gap);
  }
}
