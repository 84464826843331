.neos-content {
  font-size: rem(14);
  line-height: 1.5;
  font-weight: 400;
  color: var(--body-text-color);
  margin: 0;

  a {
    pointer-events: auto;
    text-decoration: underline;
    position: relative;
    overflow: hidden;
    transition: .2s color ease-in-out;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    box-decoration-break: clone;

    @include active {
      color: var(--primary-color-d15);

      &:after {
        transform: scaleX(0);
      }
    }
  }

  table {
    border-collapse: collapse;
    border: 1px solid #e9e9e9;

    @include high-contrast {
      border: 2px solid $black;
    }

    th, td {
      padding: 6px 10px;
      scroll-snap-align: start;
    }

    tr th {
      background: var(--primary-color) !important;
      color: $white;
      font-size: rem(12);
      font-weight: 700;
      text-align: left;
    }

    tr td {
      font-size: rem(14);
      border: 1px solid #e9e9e9;
    }
  }

  figure.table {
    width: 100%;
    overflow: auto;
    margin: var(--section-vertical-gap) 0;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-snap-type: mandatory;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-destination: 0 0;
  }

  blockquote {
    padding: 20px 10px;
    margin: var(--section-vertical-gap) 0;

    h5 {
      margin: 0 0 10px 0;
      font-size: rem(12);
      text-transform: uppercase;
      font-weight: 700;
    }

    &.primary {
      background-color: var(--primary-color-light);
      color: var(--dark-color);
    }

    &.secondary {
      background: var(--divider-color);
      color: $white;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    color: var(--dark-color);
    line-height: 1.2;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: rem(21);
  }

  h4 {
    font-size: rem(16);
    margin: var(--section-vertical-gap) 0;
  }

  ol,
  ul {
    padding: 0 0 0 14px;
    margin: var(--section-vertical-gap) 0;
  }

  ul {
    list-style: none;

    li {
      position: relative;
      margin-bottom: 3px;
    }

    li:before {
      content: '–';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      height: 1em;
      width: 14px;
      transform: translate(-100%, 0);
    }
  }

  em {
    font-weight: 700;
  }
}
