.breadcrumbs {
  padding: 20px 0 0 0;
  margin: 0 var(--section-horizontal-gap) 2px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  z-index: 20;

  li + li:before {
    content: '/';
    margin-right: 2px;
    margin-left: 2px;
    vertical-align: middle;
    font-size: rem(13);
  }

  span, a {
    display: inline-block;
    font-size: rem(11);
    font-weight: 700;
    line-height: 1;
    color: var(--body-text-color);
    text-decoration: none;
  }

  a {
    @include active {
      color: var(--primary-color);
    }
  }
}
