//.btn-custom {
//  @include button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%));
//}

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin generate-select($color) {
  position: relative;
  max-width: 680px;

  select {
    cursor: pointer;
    border: none;
    background: $white;
    outline: none;
    width: 100%;
    border-radius: $border-radius;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $color;
    font-size: 1rem;
    line-height: 1;
    padding: 17px 30px;
    box-shadow: 0 0 0 0 var(--primary-color-o30);
    will-change: box-shadow;
    transition: .2s box-shadow ease-in-out;

    &:focus option:first-child:disabled {
      display: none;
    }

    &:focus {
      box-shadow: 0 0 10px 3px var(--primary-color-o30);
    }
  }

  .icon-angle-down-md {
    display: block;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 25px;
    font-size: .6666rem;
    color: $color;
    transform: translate(0, -50%);
  }
}

.btn-none {
  color: $black;
  border: none;
  background: none;
  outline: none !important;
  box-shadow: none !important;
}

input {
  border: none;
  -webkit-appearance: none;
  @include placeholder(rgba(0, 0, 0, 0.32));
  outline: none !important;

  &:not(:placeholder-shown):invalid {
    box-shadow: 0 9px 21px 0 rgba(#100b57, .25), 0 5px 15px 0 rgba(255, 0, 0, .3) inset;
  }

  &:valid {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  &::placeholder {
    opacity: 1;
    text-indent: 0;
    transition: .3s opacity ease-in-out, .2s text-indent ease-in-out;
  }

  &:focus {
    &::placeholder {
      opacity: 0;
      text-indent: -25px;
      transition: .2s opacity ease-in-out, .3s text-indent ease-in-out;
    }
  }

}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::-webkit-search-results-button {
  -webkit-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
}

.btn {
  white-space: nowrap;
  box-shadow: none !important;
  outline: none !important;
}
