.notifications {
  .icon-bell {
    width: 12px;
    height: 12px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgd2lkdGg9IjIyIiBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMTEgMTIuNCI+CiAgPGcgZmlsbD0iIzE2MTYxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU4MS43NzQgLTUwLjA1MykiPgogICAgPHBhdGgKICAgICAgZD0iTTU4My4yNDIsNjAuODgzQTEuNDY4LDEuNDY4LDAsMCwxLDU4MS44LDU5LjY3YTEuNjY3LDEuNjY3LDAsMCwxLC40MTItMS40MjIsMi4yMzEsMi4yMzEsMCwwLDAsLjYzMy0xLjQ3MmwtLjA3LS42ODRhMy45NjYsMy45NjYsMCwwLDEsNy44MTItMS4zNzdsLjI1My43NzhhMS44NTYsMS44NTYsMCwwLDAsLjk4Mi45MzgsMS42ODYsMS42ODYsMCwwLDEsMS4wNywxLjI4MywxLjQ2OCwxLjQ2OCwwLDAsMS0xLjE5MSwxLjdsLTguMiwxLjQ0NkExLjUwOCwxLjUwOCwwLDAsMSw1ODMuMjQyLDYwLjg4M1ptMy40NTEtOC42NTZhMy4yMjIsMy4yMjIsMCwwLDAtMi42MzIsMS4zNjcsMy4xODQsMy4xODQsMCwwLDAtLjUzLDIuMzk0bC4wNzUuNzVhMi45NTYsMi45NTYsMCwwLDEtLjgyOSwyLjAyNC45MDYuOTA2LDAsMCwwLS4yMjUuNzc1LjcuNywwLDAsMCwuODE1LjU3MWw4LjItMS40NDZhLjcuNywwLDAsMCwuNTcxLS44MTYuOTE4LjkxOCwwLDAsMC0uNTg0LS43LDIuNjM0LDIuNjM0LDAsMCwxLTEuNDIyLTEuMzcxbC0uMjk0LS45YTMuMiwzLjIsMCwwLDAtMy4xNDctMi42NDhNNTg0Ljk2Nyw1Mi41MTdhLjM4Mi4zODIsMCwwLDEtLjM3Ni0uMzE1LDIuMDczLDIuMDczLDAsMCwxLC4yMDUtMS4zNTUsMS40MjUsMS40MjUsMCwwLDEsMi4yNjMtLjQsMi4wODEsMi4wODEsMCwwLDEsLjY1NiwxLjIuMzgyLjM4MiwwLDAsMS0uNzUyLjEzMiwxLjMwNywxLjMwNywwLDAsMC0uNDA3LS43NjEuNjY2LjY2NiwwLDAsMC0xLjA5MS4xOTIsMS4zLDEuMywwLDAsMC0uMTIyLjg1NS4zODEuMzgxLDAsMCwxLS4zMS40NDJBLjM2OS4zNjksMCwwLDEsNTg0Ljk2Nyw1Mi41MTdNNTg1LjkwOCw2Mi4xMzFjLS41LDAtMS4yODQtLjI2OS0xLjU2My0xLjg0OWEuMzgyLjM4MiwwLDAsMSwuNzUyLS4xMzNjLjE1NS44ODQuNDY4LDEuMjkuOTI3LDEuMjA5YTEuNTU5LDEuNTU5LDAsMCwwLC44NzMtLjU3MiwxLjMsMS4zLDAsMCwwLC4zMS0xLjAwOS4zODIuMzgyLDAsMSwxLC43NTItLjEzMiwyLjAyNywyLjAyNywwLDAsMS0uNDY1LDEuNjE2LDIuMzEzLDIuMzEzLDAsMCwxLTEuMzM4Ljg0OEExLjM1MSwxLjM1MSwwLDAsMSw1ODUuOTA4LDYyLjEzMVoiLz4KICA8L2c+Cjwvc3ZnPgo=");
    margin-right: 4px;
  }

  li.active {
    background-color: var(--body-background);
    color: var(--primary-color);

    .icon-bell {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDIyIDI1Ij4KICA8cGF0aAogICAgZmlsbD0iIzc1Yjk0ZSIKICAgIGQ9Ik0yLjg5IDIxLjc4YTIuODg5IDIuODg5IDAgMCAxLTIuODM5LTIuMzg0IDMuMjc3IDMuMjc3IDAgMCAxIC44MS0yLjc5NGMuNTY4LS42MjQgMS4yNDUtMS42MzIgMS4yNDQtMi44OTJsLS4xMzctMS4zNDRDMS4yMyA4LjE4OCA0LjA2NSA0LjEzNiA4LjMgMy4zOWM0LjIxMy0uNzQzIDguMjUxIDIuMDY2IDkuMDIyIDYuMjdsLjQ5NyAxLjUyN2MuNDE4Ljg1NSAxLjExNiAxLjU0MyAxLjkyOCAxLjg0NWEzLjMxNSAzLjMxNSAwIDAgMSAyLjEwNCAyLjUyMSAyLjg3IDIuODcgMCAwIDEtLjQ3OSAyLjE1NSAyLjg2NCAyLjg2NCAwIDAgMS0xLjg2IDEuMTg2TDMuMzkxIDIxLjczNmEyLjk1MyAyLjk1MyAwIDAgMS0uNTAyLjA0NE02LjI3NCA1LjM0YS43NS43NSAwIDAgMS0uNzM4LS42MmMtLjE2Ni0uOTQyLS4wMjMtMS44ODguNDAyLTIuNjYzQzYuMzk0IDEuMjI4IDcuMTEzLjY5IDcuOTY0LjU0YzEuNjk3LS4yOTQgMy4zNTcgMS4wOTIgMy43MTIgMy4xYS43NS43NSAwIDAgMS0xLjQ3OC4yNmMtLjIwNy0xLjE3My0xLjEwNS0yLjAzMy0xLjk3My0xLjg4My0uMzk1LjA3LS43NC4zNDEtLjk3Mi43NjMtLjI2LjQ3Ny0uMzQ3IDEuMDczLS4yMzkgMS42OGEuNzUxLjc1MSAwIDAgMS0uNzQuODgxTTUuNjY4IDIyLjg1NmMuNzEgMS4zODcgMS43MzMgMS42NzcgMi40NTcgMS42NzcuMTg2IDAgLjM1Mi0uMDE5LjQ4OC0uMDQzIDEuMzA2LS4yMyAyLjYzLTEuMzM2IDMuMjM4LTIuNzI0eiIvPgo8L3N2Zz4K");
    }

    .notification__header,
    .notification__body {
      color: var(--primary-color);
    }
  }

  li {
    color: var(--body-text-color);
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 2px;
      background-image: var(--divider-color);
    }
  }
}

.notification {
  display: block;
  position: relative;
  padding: var(--section-horizontal-gap) 50px var(--section-horizontal-gap) var(--section-horizontal-gap);
  -webkit-tap-highlight-color: var(--primary-color-o20);
  text-decoration: none;

  &.notification--has-link {
    @include link-icon-right;

    text-decoration: none;
    display: block;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 var(--negative-section-horizontal-gap);
  }

  &__header {
    font-size: rem(11);
    color: #989898;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
  }

  &__body {
    display: block;
    font-size: rem(14);
    font-weight: 700;
    color: var(--body-text-color);
    line-height: 1.25;
  }
}
