.glide {
  &__wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 0;
  }

  &__slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &s {
      margin: 0;
      overflow: visible;
    }
  }

  &__track {
    overflow: visible;
  }

  &__bullet {
    width: 16px;
    height: 0;
    display: block;
    border-radius: 0;
    border: none;
    outline: none !important;
    background: none;
    margin: 0;
    padding: 15px 2px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 2px;
      right: 2px;
      transform: translate(0, -50%);
      border: 6px solid;
      color: $white;
      transition: .2s color ease-in-out;
    }

    &--active:after {
      color: var(--primary-color);
    }

    @include active {
      &:after {
        color: var(--primary-color-l20);
      }
    }

    &s {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 5px var(--section-horizontal-gap);
      align-items: center;
      justify-content: flex-end;
      pointer-events: none;
    }
  }
}

.slide {
  width: 100%;
  position: relative;

  &__video,
  &__image {
    display: block;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 0;
      position: relative;
      padding-bottom: 56.25%;
      display: block;
      pointer-events: none;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__video{
    position: relative;
    overflow: hidden;

    iframe {
      pointer-events: none;
      position: absolute;
      top: -100%;
      width: 100%;
      height: 300%;
    }
  }
}
