
/*shows the stars side by side, centered, and in reverse order than the HMTL*/
.codeq-survey-form {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

/*hides the radio buttons*/
.codeq-survey-form__rating-input {
    display: none;
}

/*style the empty stars, sets position:relative as base for pseudo-elements*/
.codeq-survey-form__rating-label {
    position: relative;
    width: 1.1em;
    color: #FFD700;
    cursor: pointer;

    /* sets filled star pseudo-elements */
    &::before {
        content: "\2605";
        position: absolute;
        opacity: 0;
    }

    /*overlays a filled start character to the hovered element and all previous siblings*/
    &:hover:before,
    &:hover ~ label:before {
        opacity: 1 !important;
    }

    /*overlays a filled start character on the selected element and all previous siblings*/
    .codeq-survey-form__rating-input:checked ~ &:before {
        opacity: 1;
    }

    /*when an element is selected and pointer re-enters the rating container, selected rate and siblings get semi transparent, as reminder of current selection*/
    .codeq-survey-form:hover > & {
        opacity: 0.4;
    }
}

/* when js works, we do not need a submit button */
.codeq-survey-form--has-js .codeq-survey-form__submit-button,
body.neos-backend .codeq-survey-form__submit-button {
    display: none;
}

/* process styles */
.codeq-survey-form--is-submitting {
    pointer-events: none;
    opacity: 0.5;
}

.codeq-survey-form--is-submitted {
    font-style: italic;
    color: green;
    opacity: 0.5;
}

.codeq-survey-form--was-submitted {
    font-weight: normal;
    font-style: italic;
    color: green;
    opacity: 0.5;
}

.codeq-survey-component__submission-results {
    font-weight: normal;
    font-style: italic;

    .codeq-survey-component:not(.codeq-survey-component--show-submission-results) & {
        display: none;
    }
}
