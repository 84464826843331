@charset "UTF-8";
@import url(~cookieconsent/build/cookieconsent.min.css);
@import url(~photoswipe/dist/photoswipe.css);
@import url(~photoswipe/dist/default-skin/default-skin.css);
:root {
  --primary-color: #75b94e;
  --primary-color-o10: rgba(#75b94e, .1);
  --primary-color-o20: rgba(#75b94e, .2);
  --primary-color-o30: rgba(#75b94e, .3);
  --primary-color-d15: darken(#75b94e, 15%);
  --primary-color-d20: darken(#75b94e, 20%);
  --primary-color-d25: darken(#75b94e, 25%);
  --primary-color-l20: lighten(#75b94e, 20%);
  --primary-color-light: #c8e3b8;
  --primary-color-dark: #315a19;
  --secondary-color: #aaa;
  --dark-color: #272726;
  --light-color: #272726;
  --header-background: #fff;
  --body-background: #f4f4f4;
  --body-text-color: #666;
  --grey-color: #e9e9e9;
  --grey-light-color: #ddd;
  --divider-color: linear-gradient(to right, #dddddd 0%, #cccccc 100%);
  --safe-area-inset-top: 0;
  --safe-area-inset-bottom: 0;
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  /*
  @media (prefers-color-scheme: dark) {
    --primary-color: #75b94e;
    --primary-color-o10: rgba(#75b94e, .1);
    --primary-color-o20: rgba(#75b94e, .2);
    --primary-color-o30: rgba(#75b94e, .3);
    --primary-color-d15: darken(#75b94e, 15%);
    --primary-color-d20: darken(#75b94e, 20%);
    --primary-color-d25: darken(#75b94e, 25%);
    --primary-color-l20: lighten(#75b94e, 20%);
    --primary-color-light: #315a19;
    --primary-color-dark: #c8e3b8;
    --secondary-color: #aaa;
    --dark-color: #fff;
    --light-color: #fff;
    --header-background: #333;
    --body-background: #262626;
    --body-text-color: #cecece;
    --grey-color: #333;
    --grey-light-color: #181818;
    --divider-color: linear-gradient(to right, #2a2a2a 0%, #2f2f2f 100%);
  }
  */ }
  @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    :root {
      --primary-color: #75b94e;
      --primary-color-o10: #75b94e;
      --primary-color-o20: #75b94e;
      --primary-color-o30: #75b94e;
      --primary-color-d15: #fff;
      --primary-color-d20: #fff;
      --primary-color-d25: #fff;
      --primary-color-l20: #000;
      --primary-color-light: #75b94e;
      --secondary-color: #000;
      --dark-color: #000;
      --light-color: #000;
      --header-background: #fff;
      --body-background: #fff;
      --body-text-color: #000;
      --grey-color: #eee;
      --grey-light-color: #fff;
      --divider-color: linear-gradient(to top, #000 8px, #fff 8px, #fff 100%); } }

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .glide * {
    box-sizing: inherit; }
  .glide__track {
    overflow: hidden; }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform; }
    .glide__slides--dragging {
      user-select: none; }
  .glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent; }
    .glide__slide a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none; }
  .glide__arrows {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide__bullets {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide--rtl {
    direction: rtl; }

.cc-btn {
  text-decoration: none; }

*, *::before, *::after {
  box-sizing: border-box; }

img {
  user-select: none; }

a {
  color: var(--primary-color);
  text-decoration: underline; }

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.fs {
  position: absolute;
  width: 100%;
  height: 100%; }

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.color-dark {
  color: var(--dark-color) !important; }

.btn-none {
  color: #000;
  border: none;
  background: none;
  outline: none !important;
  box-shadow: none !important; }

input {
  border: none;
  -webkit-appearance: none;
  outline: none !important; }
  input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.32); }
  input:-moz-placeholder {
    color: rgba(0, 0, 0, 0.32); }
  input::-moz-placeholder {
    color: rgba(0, 0, 0, 0.32); }
  input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.32); }
  input:not(:placeholder-shown):invalid {
    box-shadow: 0 9px 21px 0 rgba(16, 11, 87, 0.25), 0 5px 15px 0 rgba(255, 0, 0, 0.3) inset; }
  input:valid {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0); }
  input::placeholder {
    opacity: 1;
    text-indent: 0;
    transition: .3s opacity ease-in-out, .2s text-indent ease-in-out; }
  input:focus::placeholder {
    opacity: 0;
    text-indent: -25px;
    transition: .2s opacity ease-in-out, .3s text-indent ease-in-out; }

::-webkit-search-cancel-button {
  -webkit-appearance: none; }

::-webkit-search-results-button {
  -webkit-appearance: none; }

input[type=search] {
  -webkit-appearance: none; }

.btn {
  white-space: nowrap;
  box-shadow: none !important;
  outline: none !important; }

@font-face {
  font-family: "DMSans";
  src: url("../fonts/DMSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: "DMSans";
  src: url("../fonts/DMSans-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: "DMSans";
  src: url("../fonts/DMSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "DMSans";
  src: url("../fonts/DMSans-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: "DMSans";
  src: url("../fonts/DMSans-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "DMSans";
  src: url("../fonts/DMSans-RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic; }

:root {
  --h1: rem(48);
  --h2: rem(40);
  --h3: rem(32);
  --h4: rem(24);
  --h5: rem(18);
  --h6: rem(16);
  --p: rem(14);
  --subtitle: rem(14); }

h1, .h1 {
  font-size: var(--h1); }

h2, .h2 {
  font-size: var(--h2); }

h3, .h3 {
  font-size: var(--h3); }

h4, .h4 {
  font-size: var(--h4); }

h5, .h5 {
  font-size: var(--h5); }

h6, .h6 {
  font-size: var(--h6); }

.subtitle {
  font-size: var(--subtitle);
  line-height: 1.2; }

p, .p {
  font-size: var(--p);
  line-height: 1.4;
  margin-bottom: 1em; }
  p:last-child, .p:last-child {
    margin-bottom: 0; }

p.suptitle {
  font-size: var(--suptitle); }

p.quote {
  font-style: italic;
  padding: 0 15px; }

small {
  font-size: var(--subtitle); }

mark {
  background: var(--primary-color-o30); }

:root {
  --section-horizontal-gap: 12px;
  --negative-section-horizontal-gap: -12px;
  --section-vertical-gap: 20px; }

html {
  margin: 0;
  padding: 0; }

body {
  padding: 0 0 var(--footer-height);
  margin: 0;
  width: 100%;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: stretch;
  overflow-x: hidden;
  will-change: opacity;
  transition: .3s opacity ease-in-out;
  background-color: var(--body-background);
  font-family: "DMSans", sans-serif;
  word-wrap: break-word; }
  body header + main > *:first-child {
    margin-top: var(--header-height) !important; }
  body *::-moz-selection {
    background: #3c6126;
    color: #fff; }
  body *::selection {
    background: #3c6126;
    color: #fff; }
  @media (prefers-color-scheme: dark) {
    body *::-moz-selection {
      background: #bcdda9;
      color: #fff; }
    body *::selection {
      background: #bcdda9;
      color: #fff; } }
  body.body--prevent-scroll {
    overflow: hidden; }

main {
  width: 100%;
  flex-grow: 1;
  align-items: stretch;
  justify-content: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: 20;
  position: relative;
  max-width: 600px;
  margin: 0 auto; }

section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: var(--body-background);
  padding: var(--section-vertical-gap) var(--section-horizontal-gap); }
  section:last-child {
    margin-bottom: 0; }

.container {
  max-width: 600px;
  position: relative;
  margin: 0 auto; }

.page-title {
  color: var(--secondary-color);
  letter-spacing: -0.72px;
  margin-bottom: 0;
  padding: 20px var(--section-horizontal-gap) 0;
  background-color: var(--body-background);
  font-size: 1.5rem;
  font-weight: 700; }
  .breadcrumbs + .page-title {
    margin-top: -22px; }

.neos-backend .main-contentcollection:empty {
  background: var(--header-background); }
  .neos-backend .main-contentcollection:empty:before {
    display: block;
    content: 'Inhalt hier hinzuf\00FCgen';
    font-size: 2rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
    text-align: center; }

.glide__wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 0; }

.glide__slide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .glide__slides {
    margin: 0;
    overflow: visible; }

.glide__track {
  overflow: visible; }

.glide__bullet {
  width: 16px;
  height: 0;
  display: block;
  border-radius: 0;
  border: none;
  outline: none !important;
  background: none;
  margin: 0;
  padding: 15px 2px;
  position: relative; }
  .glide__bullet:after {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 2px;
    right: 2px;
    transform: translate(0, -50%);
    border: 6px solid;
    color: #fff;
    transition: .2s color ease-in-out; }
  .glide__bullet--active:after {
    color: var(--primary-color); }
  .glide__bullet:focus:after, .glide__bullet:active:after, .glide__bullet:active:focus:after {
    color: var(--primary-color-l20); }
  .glide__bullets {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 5px var(--section-horizontal-gap);
    align-items: center;
    justify-content: flex-end;
    pointer-events: none; }

.slide {
  width: 100%;
  position: relative; }
  .slide__video, .slide__image {
    display: block;
    position: relative; }
    .slide__video:after, .slide__image:after {
      content: '';
      width: 100%;
      height: 0;
      position: relative;
      padding-bottom: 56.25%;
      display: block;
      pointer-events: none; }
    .slide__video img, .slide__image img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .slide__video {
    position: relative;
    overflow: hidden; }
    .slide__video iframe {
      pointer-events: none;
      position: absolute;
      top: -100%;
      width: 100%;
      height: 300%; }

.gallery {
  margin: 0 0 var(--section-vertical-gap) 0;
  column-count: 2;
  column-fill: balance;
  column-rule-width: 0;
  column-width: calc(50vw - var(--double-columns-gap));
  column-gap: var(--columns-gap); }
  @media screen and (min-width: 600px) {
    .gallery {
      column-width: 283px; } }
  .gallery a {
    display: block;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    padding: 0;
    margin-bottom: var(--columns-gap); }
    .gallery a:after {
      display: none !important; }
    @supports (-webkit-touch-callout: none) {
      .gallery a:first-child {
        margin-top: var(--columns-gap); } }
    .gallery a img {
      display: block;
      width: 100%;
      height: auto; }

.betterembed {
  position: relative;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto; }
  .betterembed,
  .betterembed * {
    box-sizing: border-box; }
  .betterembed a *,
  .betterembed button * {
    pointer-events: none; }
  .betterembed a:hover, .betterembed a:focus,
  .betterembed button:hover,
  .betterembed button:focus {
    outline: none; }
  .betterembed.is-betterembed-remote-visible .betterembed-switch__hide,
  .betterembed.is-betterembed-remote-visible .betterembed__embed {
    display: block; }
  .betterembed.is-betterembed-remote-visible .betterembed-switch__show,
  .betterembed.is-betterembed-remote-visible .betterembed__body {
    display: none; }

.betterembed-switch__hide {
  display: none; }

.betterembed-switch,
.betterembed-switch__show {
  display: block; }

.betterembed__top {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 5px;
  align-items: baseline; }
  .betterembed__top > * {
    margin: 0 5px; }

.betterembed__network {
  word-break: break-word;
  hyphens: auto;
  line-height: 1;
  text-transform: uppercase;
  flex: 1;
  min-width: 50%; }

.betterembed-switch {
  padding: 0;
  cursor: pointer;
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center; }
  .betterembed-switch:hover .betterembed__logo, .betterembed-switch:focus .betterembed__logo {
    transform: scale(1.1); }
  .betterembed-switch:active .betterembed__logo {
    transform: scale(1.05); }
  .betterembed-switch .betterembed__logo {
    display: block;
    margin-left: 5px;
    width: 23px;
    height: 23px;
    transition: transform 0.2s ease;
    will-change: transform; }

.betterembed__body {
  background: #4c5559;
  color: #fff;
  display: flex;
  flex-direction: column; }
  .betterembed__body > :not(.betterembed__media) {
    margin: 15px 15px 0; }
  .betterembed__body > :last-child {
    padding-bottom: 15px; }
  .betterembed__body a {
    color: inherit;
    text-decoration: underline; }
    .betterembed__body a:hover, .betterembed__body a:focus {
      color: inherit;
      text-decoration: none; }

.betterembed__media {
  order: -1;
  margin: 0; }
  .betterembed__media img {
    width: 100%;
    height: auto; }

.betterembed__header {
  word-break: break-word;
  hyphens: auto; }
  .betterembed__header * {
    font-size: 1.5em;
    color: inherit;
    margin: 0; }

.betterembed__text {
  word-break: break-word;
  hyphens: auto; }

.betterembed__read-more {
  font-size: 80%;
  white-space: nowrap; }

.betterembed__dialog {
  position: absolute;
  top: 40px;
  left: 15px;
  right: 15px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.84);
  color: #fff;
  text-align: center;
  pointer-events: none;
  transition: opacity 0.2s ease, transform 0.2s ease;
  opacity: 0;
  transform: translateY(-20px); }
  .is-betterembed-dialog-visible:not(.is-betterembed-remote-visible) .betterembed__dialog {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all; }
  .betterembed__dialog p {
    text-align: left;
    margin: 0; }
    .betterembed__dialog p:not(:last-of-type) {
      margin-bottom: 1em; }

.betterembed__button {
  display: inline-block;
  margin: 20px 5px 0;
  padding: 10px 20px;
  cursor: pointer;
  color: inherit;
  background-color: transparent;
  border: 1px solid transparent; }

.betterembed__button--secondary:hover, .betterembed__button--secondary:focus {
  text-decoration: underline; }

.betterembed__button--primary {
  border-color: #fff;
  transition: color 0.2s ease, background-color 0.2s ease; }
  .betterembed__button--primary:hover, .betterembed__button--primary:focus {
    background-color: #fff;
    color: #000; }

.betterembed__footer {
  padding-top: 15px;
  border-top: 1px solid currentColor;
  overflow: auto; }

.betterembed__author.betterembed__author {
  font-weight: bold;
  text-decoration: none; }
  .betterembed__author.betterembed__author:hover, .betterembed__author.betterembed__author:focus {
    text-decoration: underline; }

.betterembed__embed {
  display: none; }
  .betterembed__embed > * {
    width: 100% !important;
    overflow-x: hidden;
    margin: 0;
    padding: 0; }

.better-embed-placeholder {
  padding: 1em;
  border: 0.3em #f15a24 solid;
  position: relative;
  margin: 2em 1em; }
  .better-embed-placeholder::before, .better-embed-placeholder::after {
    content: "";
    display: block;
    position: absolute;
    background: #fff; }
  .better-embed-placeholder::before {
    top: -0.3em;
    bottom: -0.3em;
    left: 1em;
    right: 1em; }
  .better-embed-placeholder::after {
    top: 1em;
    bottom: 1em;
    left: -0.3em;
    right: -0.3em; }

.better-embed-placeholder__heading {
  position: relative;
  z-index: 1;
  font-size: 1.5em;
  text-align: center;
  font-family: sans-serif;
  line-height: 1.15; }

/*shows the stars side by side, centered, and in reverse order than the HMTL*/
.codeq-survey-form {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center; }

/*hides the radio buttons*/
.codeq-survey-form__rating-input {
  display: none; }

/*style the empty stars, sets position:relative as base for pseudo-elements*/
.codeq-survey-form__rating-label {
  position: relative;
  width: 1.1em;
  color: #FFD700;
  cursor: pointer;
  /* sets filled star pseudo-elements */
  /*overlays a filled start character to the hovered element and all previous siblings*/
  /*overlays a filled start character on the selected element and all previous siblings*/
  /*when an element is selected and pointer re-enters the rating container, selected rate and siblings get semi transparent, as reminder of current selection*/ }
  .codeq-survey-form__rating-label::before {
    content: "\2605";
    position: absolute;
    opacity: 0; }
  .codeq-survey-form__rating-label:hover:before,
  .codeq-survey-form__rating-label:hover ~ label:before {
    opacity: 1 !important; }
  .codeq-survey-form__rating-input:checked ~ .codeq-survey-form__rating-label:before {
    opacity: 1; }
  .codeq-survey-form:hover > .codeq-survey-form__rating-label {
    opacity: 0.4; }

/* when js works, we do not need a submit button */
.codeq-survey-form--has-js .codeq-survey-form__submit-button,
body.neos-backend .codeq-survey-form__submit-button {
  display: none; }

/* process styles */
.codeq-survey-form--is-submitting {
  pointer-events: none;
  opacity: 0.5; }

.codeq-survey-form--is-submitted {
  font-style: italic;
  color: green;
  opacity: 0.5; }

.codeq-survey-form--was-submitted {
  font-weight: normal;
  font-style: italic;
  color: green;
  opacity: 0.5; }

.codeq-survey-component__submission-results {
  font-weight: normal;
  font-style: italic; }
  .codeq-survey-component:not(.codeq-survey-component--show-submission-results) .codeq-survey-component__submission-results {
    display: none; }

.voucher-page__btn {
  font-family: 'DM Sans', sans-serif;
  border: 0 none;
  background: var(--primary-color);
  color: var(--header-background);
  font-size: 1.25rem;
  padding: .5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  transition: all .3s ease; }
  .voucher-page__btn[disabled] {
    background: var(--primary-color-light);
    box-shadow: 0 0 0 0 !important; }

.voucher-page__btn-cart {
  position: fixed;
  right: 0;
  bottom: 70px;
  max-width: 0;
  max-height: 0;
  opacity: 0;
  transition: all .5s ease;
  z-index: 999999; }
  .voucher-page__btn-cart--visible {
    max-width: 200px;
    max-height: 200px;
    opacity: 1; }

.voucher-page__overview {
  background-color: #E9E9E9; }
  .voucher-page__overview h2 {
    font-size: 24px; }
  .voucher-page__overview-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
    background-color: #DDDDDD;
    margin-bottom: 3px; }
  .voucher-page__overview-list__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .5rem; }

.voucher-page__formContainer {
  background-color: #E9E9E9;
  padding-top: 0;
  padding-bottom: var(--section-vertical-gap);
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all .3s ease; }
  .voucher-page__formContainer--is-visible {
    max-height: 1000px;
    opacity: 1; }

.voucher-page__form {
  display: flex;
  flex-direction: column; }
  .voucher-page__form input[type='text'],
  .voucher-page__form input[type='email'],
  .voucher-page__form input[type='date'],
  .voucher-page__form select {
    font-family: 'DM Sans', sans-serif;
    padding: .5rem;
    margin-bottom: .25rem;
    font-size: 16px; }
  .voucher-page__form input[type='date'] {
    width: 100%; }
  .voucher-page__form select {
    background-color: #fff;
    padding: 0.375rem 0.5rem;
    border: none;
    outline: none !important; }
  .voucher-page__form ::placeholder {
    color: #272726 !important; }
  .voucher-page__form ::-webkit-input-placeholder {
    color: #272726 !important; }
  .voucher-page__form-submitButton {
    font-size: 16px;
    font-weight: bold; }

.voucher-item {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  .voucher-item__content-text {
    padding-bottom: 1rem; }
  .voucher-item__content figure > img {
    max-width: 100%; }
  .voucher-item__variants-item, .voucher-item__sum {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .5rem; }
  .voucher-item__sum {
    font-weight: bold;
    color: #666666;
    transition: all .3s ease;
    max-height: 0;
    overflow: hidden;
    opacity: 0; }
  .voucher-item--selected .voucher-item__sum {
    max-height: 100px;
    opacity: 1; }
  .voucher-item__variants-item {
    color: #272726;
    background-color: #E9E9E9;
    margin-bottom: 3px;
    transition: background-color .3s ease; }
    .voucher-item__variants-item--selected {
      background-color: #CCCCCC; }
  .voucher-item__variants-item-form-inputField {
    height: 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 0 1rem;
    text-align: center;
    vertical-align: bottom;
    width: 75px;
    font-size: 14px;
    -moz-appearance: textfield; }
    .voucher-item__variants-item-form-inputField::-webkit-outer-spin-button, .voucher-item__variants-item-form-inputField::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .voucher-item__variants-item-form-increaseBtn, .voucher-item__variants-item-form-decreaseBtn {
    height: 2rem;
    width: 2rem;
    border-radius: 7px;
    padding: 0 !important;
    z-index: 10;
    position: relative; }

.merchandise-shop__hint {
  font-size: 14px;
  color: #666666; }

.merchandise-shop__images {
  padding-top: var(--section-vertical-gap);
  column-count: 2;
  column-gap: 10px;
  margin-bottom: 10px; }
  .merchandise-shop__images figure {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid; }
    .merchandise-shop__images figure > img {
      max-width: 100%;
      display: block;
      grid-row: 1 / -1;
      grid-column: 1; }

.merchandise-shop__motives {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 20px; }

.merchandise-shop__motive {
  text-align: center;
  cursor: pointer; }
  .merchandise-shop__motive * {
    pointer-events: none; }
  .merchandise-shop__motive__image {
    display: flex;
    width: 100%;
    height: 200px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 5px solid #FFFFFF;
    border-radius: 7px;
    transition: border .3s ease;
    padding: 1rem; }
    .merchandise-shop__motive__image img {
      margin: auto;
      width: 100%;
      max-width: 100%;
      max-height: 100%; }
  .merchandise-shop__motive__label {
    display: block;
    margin-top: 11px;
    color: #666666; }
  .merchandise-shop__motive.merchandise-shop__spec-item--selected .merchandise-shop__motive__image {
    border: 5px solid #C8E3B8; }

.merchandise-shop__pills {
  padding-bottom: var(--section-vertical-gap); }
  .merchandise-shop__pills__item {
    border-radius: 11px;
    background-color: #FFFFFF;
    margin: 0 .5rem .5rem 0;
    padding: .5rem;
    color: #666666;
    display: inline-block;
    text-decoration: none !important;
    font-size: 14px;
    transition: all .3s ease;
    box-shadow: 0 3px 6px #00000029;
    cursor: pointer;
    min-width: 70px;
    text-align: center; }
    .merchandise-shop__pills__item.merchandise-shop__spec-item--selected {
      background-color: #C8E3B8;
      box-shadow: none; }

.merchandise-shop__form {
  display: flex;
  flex-direction: column; }
  .merchandise-shop__form input[type='text'],
  .merchandise-shop__form input[type='email'],
  .merchandise-shop__form input[type='date'],
  .merchandise-shop__form select {
    font-family: 'DM Sans', sans-serif;
    padding: .5rem;
    margin-bottom: .25rem;
    font-size: 16px; }
  .merchandise-shop__form input[type='date'] {
    width: 100%; }
  .merchandise-shop__form select {
    background-color: #fff;
    padding: 0.375rem 0.5rem;
    border: none;
    outline: none !important; }
  .merchandise-shop__form ::placeholder {
    color: #272726 !important; }
  .merchandise-shop__form ::-webkit-input-placeholder {
    color: #272726 !important; }
  .merchandise-shop__form__submit-button {
    font-weight: bold;
    font-family: 'DM Sans', sans-serif;
    border: 0 none;
    background: var(--primary-color);
    color: var(--header-background);
    font-size: 1.25rem;
    padding: .5rem;
    margin-bottom: 2rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
    transition: all .3s ease; }
    .merchandise-shop__form__submit-button[disabled] {
      background: var(--primary-color-light);
      box-shadow: 0 0 0 0 !important; }

body.body--is-closing {
  position: relative; }
  body.body--is-closing .install-prompt,
  body.body--is-closing .header__search-modal {
    display: none; }
  body.body--is-closing main {
    pointer-events: none;
    opacity: 0; }
  body.body--is-closing:before, body.body--is-closing:after {
    content: '';
    position: fixed;
    z-index: 9999;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }
  body.body--is-closing:after {
    animation-delay: -0.5s; }

@keyframes lds-ripple {
  0% {
    top: calc(50%);
    left: calc(50%);
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    opacity: 0; } }

:root {
  --header-height: calc(43px + var(--safe-area-inset-top)); }

.header {
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: var(--safe-area-inset-top);
  height: var(--header-height);
  width: 100%;
  z-index: 40;
  background-color: var(--header-background);
  font-size: 0; }
  .header .container {
    padding: 0 50px 0 var(--section-horizontal-gap); }
  .header__logo {
    display: inline-block;
    overflow: hidden;
    padding: 14px 0 7px;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    text-decoration: none; }
    .header__logo:focus .wir path, .header__logo:active .wir path, .header__logo:active:focus .wir path {
      fill: #375625; }
    .header__logo:focus .mobile, .header__logo:active .mobile, .header__logo:active:focus .mobile {
      fill: #47732e; }
    .header__logo svg {
      height: 22px;
      width: auto;
      pointer-events: none;
      display: block; }
      .header__logo svg .wir {
        fill: #649e43;
        transition: .2s fill ease-in-out; }
      .header__logo svg .mobile {
        fill: #75b94e;
        transition: .2s fill ease-in-out; }
  .header .notifications-bell, .header .header__search-modal-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    z-index: 10;
    border: none;
    box-shadow: none;
    background-position: 13px 50%;
    background-repeat: no-repeat;
    background-size: 22px 25px; }
  .header .header__search-modal-button {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgZGF0YS1uYW1lPSJFYmVuZSAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNSAyNSI+DQogIDxkZWZzPg0KICAgIDxzdHlsZT4NCiAgICAgIC5jbHMtMSB7DQogICAgICAgIGZpbGw6ICM4M2I2NWM7DQogICAgICB9DQogICAgPC9zdHlsZT4NCiAgPC9kZWZzPg0KICA8cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMi42OCwxOS4zMSwxNywxMy42MUE4LjE3LDguMTcsMCwxLDAsMTMuNjEsMTdsNS43LDUuN2EyLjM3LDIuMzcsMCwwLDAsMS42OC42OSwyLjM4LDIuMzgsMCwwLDAsMS42OS00LjA2Wk01LjA3LDE0LjQ5YTYuNjYsNi42NiwwLDEsMSw5LjQyLDBBNi42OCw2LjY4LDAsMCwxLDUuMDcsMTQuNDlabTE2LjU0LDcuMTJhLjg2Ljg2LDAsMCwxLTEuMjQsMGwtNS4yNS01LjI0LDEuMjUtMS4yNSw1LjI0LDUuMjVBLjg2Ljg2LDAsMCwxLDIxLjYxLDIxLjYxWiIvPg0KPC9zdmc+DQo=");
    right: 40px;
    width: 60px;
    background-position: 23px 50%; }
  .header .notifications-bell {
    padding-right: var(--section-horizontal-gap);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgd2lkdGg9IjIyIiBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMTEgMTIuNCI+CiAgPGcgZmlsbD0iIzE2MTYxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU4MS43NzQgLTUwLjA1MykiPgogICAgPHBhdGgKICAgICAgZmlsbD0iIzc1Yjk0ZSIKICAgICAgZD0iTTU4My4yNDIsNjAuODgzQTEuNDY4LDEuNDY4LDAsMCwxLDU4MS44LDU5LjY3YTEuNjY3LDEuNjY3LDAsMCwxLC40MTItMS40MjIsMi4yMzEsMi4yMzEsMCwwLDAsLjYzMy0xLjQ3MmwtLjA3LS42ODRhMy45NjYsMy45NjYsMCwwLDEsNy44MTItMS4zNzdsLjI1My43NzhhMS44NTYsMS44NTYsMCwwLDAsLjk4Mi45MzgsMS42ODYsMS42ODYsMCwwLDEsMS4wNywxLjI4MywxLjQ2OCwxLjQ2OCwwLDAsMS0xLjE5MSwxLjdsLTguMiwxLjQ0NkExLjUwOCwxLjUwOCwwLDAsMSw1ODMuMjQyLDYwLjg4M1ptMy40NTEtOC42NTZhMy4yMjIsMy4yMjIsMCwwLDAtMi42MzIsMS4zNjcsMy4xODQsMy4xODQsMCwwLDAtLjUzLDIuMzk0bC4wNzUuNzVhMi45NTYsMi45NTYsMCwwLDEtLjgyOSwyLjAyNC45MDYuOTA2LDAsMCwwLS4yMjUuNzc1LjcuNywwLDAsMCwuODE1LjU3MWw4LjItMS40NDZhLjcuNywwLDAsMCwuNTcxLS44MTYuOTE4LjkxOCwwLDAsMC0uNTg0LS43LDIuNjM0LDIuNjM0LDAsMCwxLTEuNDIyLTEuMzcxbC0uMjk0LS45YTMuMiwzLjIsMCwwLDAtMy4xNDctMi42NDhNNTg0Ljk2Nyw1Mi41MTdhLjM4Mi4zODIsMCwwLDEtLjM3Ni0uMzE1LDIuMDczLDIuMDczLDAsMCwxLC4yMDUtMS4zNTUsMS40MjUsMS40MjUsMCwwLDEsMi4yNjMtLjQsMi4wODEsMi4wODEsMCwwLDEsLjY1NiwxLjIuMzgyLjM4MiwwLDAsMS0uNzUyLjEzMiwxLjMwNywxLjMwNywwLDAsMC0uNDA3LS43NjEuNjY2LjY2NiwwLDAsMC0xLjA5MS4xOTIsMS4zLDEuMywwLDAsMC0uMTIyLjg1NS4zODEuMzgxLDAsMCwxLS4zMS40NDJBLjM2OS4zNjksMCwwLDEsNTg0Ljk2Nyw1Mi41MTdNNTg1LjkwOCw2Mi4xMzFjLS41LDAtMS4yODQtLjI2OS0xLjU2My0xLjg0OWEuMzgyLjM4MiwwLDAsMSwuNzUyLS4xMzNjLjE1NS44ODQuNDY4LDEuMjkuOTI3LDEuMjA5YTEuNTU5LDEuNTU5LDAsMCwwLC44NzMtLjU3MiwxLjMsMS4zLDAsMCwwLC4zMS0xLjAwOS4zODIuMzgyLDAsMSwxLC43NTItLjEzMiwyLjAyNywyLjAyNywwLDAsMS0uNDY1LDEuNjE2LDIuMzEzLDIuMzEzLDAsMCwxLTEuMzM4Ljg0OEExLjM1MSwxLjM1MSwwLDAsMSw1ODUuOTA4LDYyLjEzMVoiLz4KICA8L2c+Cjwvc3ZnPgo="); }
    .header .notifications-bell.active {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDIyIDI1Ij4KICA8cGF0aAogICAgZmlsbD0iIzc1Yjk0ZSIKICAgIGQ9Ik0yLjg5IDIxLjc4YTIuODg5IDIuODg5IDAgMCAxLTIuODM5LTIuMzg0IDMuMjc3IDMuMjc3IDAgMCAxIC44MS0yLjc5NGMuNTY4LS42MjQgMS4yNDUtMS42MzIgMS4yNDQtMi44OTJsLS4xMzctMS4zNDRDMS4yMyA4LjE4OCA0LjA2NSA0LjEzNiA4LjMgMy4zOWM0LjIxMy0uNzQzIDguMjUxIDIuMDY2IDkuMDIyIDYuMjdsLjQ5NyAxLjUyN2MuNDE4Ljg1NSAxLjExNiAxLjU0MyAxLjkyOCAxLjg0NWEzLjMxNSAzLjMxNSAwIDAgMSAyLjEwNCAyLjUyMSAyLjg3IDIuODcgMCAwIDEtLjQ3OSAyLjE1NSAyLjg2NCAyLjg2NCAwIDAgMS0xLjg2IDEuMTg2TDMuMzkxIDIxLjczNmEyLjk1MyAyLjk1MyAwIDAgMS0uNTAyLjA0NE02LjI3NCA1LjM0YS43NS43NSAwIDAgMS0uNzM4LS42MmMtLjE2Ni0uOTQyLS4wMjMtMS44ODguNDAyLTIuNjYzQzYuMzk0IDEuMjI4IDcuMTEzLjY5IDcuOTY0LjU0YzEuNjk3LS4yOTQgMy4zNTcgMS4wOTIgMy43MTIgMy4xYS43NS43NSAwIDAgMS0xLjQ3OC4yNmMtLjIwNy0xLjE3My0xLjEwNS0yLjAzMy0xLjk3My0xLjg4My0uMzk1LjA3LS43NC4zNDEtLjk3Mi43NjMtLjI2LjQ3Ny0uMzQ3IDEuMDczLS4yMzkgMS42OGEuNzUxLjc1MSAwIDAgMS0uNzQuODgxTTUuNjY4IDIyLjg1NmMuNzEgMS4zODcgMS43MzMgMS42NzcgMi40NTcgMS42NzcuMTg2IDAgLjM1Mi0uMDE5LjQ4OC0uMDQzIDEuMzA2LS4yMyAyLjYzLTEuMzM2IDMuMjM4LTIuNzI0eiIvPgo8L3N2Zz4K"); }
    .header .notifications-bell__counter {
      display: block;
      border-radius: 100%;
      position: absolute;
      top: 7px;
      right: 11px;
      padding: 0 1px;
      line-height: 12px;
      min-width: 12px;
      min-height: 12px;
      text-align: center;
      vertical-align: center;
      background-color: #b94e4e;
      color: #fff;
      box-shadow: 0 0 0 1px var(--header-background);
      font-size: 0.5rem;
      z-index: 3;
      font-weight: 700;
      pointer-events: none;
      user-select: none; }
  .header .link-back {
    display: inline-block;
    color: var(--primary-color);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    padding: 14px var(--section-horizontal-gap) 7px;
    text-decoration: none;
    margin-left: var(--negative-section-horizontal-gap); }

@keyframes scale {
  0% {
    transform: scale(1);
    opacity: .7; }
  100% {
    transform: scale(2.5);
    opacity: 0; } }

:root {
  --footer-height: 70px; }

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  padding-bottom: var(--safe-area-inset-bottom);
  width: 100%;
  z-index: 100;
  opacity: 1;
  transform: translate(0, 0);
  background: linear-gradient(to bottom, #75b94e 0%, #57893a 100%); }
  @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    .footer {
      background: #57893a;
      color: #000; } }

.navigation ul {
  list-style: none;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--section-horizontal-gap); }
  @media screen and (min-width: 600px) {
    .navigation ul {
      justify-content: center; } }
  .navigation ul li {
    width: 100%;
    opacity: 1;
    max-width: 120px; }
    .navigation ul li.active a {
      opacity: 1;
      box-shadow: 0 -4px 0 0 #fff inset; }
      @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
        .navigation ul li.active a {
          box-shadow: 0 -4px 0 0 #000 inset; } }

.navigation a {
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: var(--footer-height);
  width: 100%;
  fill: #fff;
  color: #fff;
  opacity: .45;
  font-size: 0.6875rem;
  line-height: 1;
  background-color: transparent;
  transition: .2s opacity ease-in-out, .2s background-color ease-in-out; }
  .navigation a:focus, .navigation a:active, .navigation a:active:focus {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.025); }
  @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
    .navigation a {
      opacity: 1;
      color: #000;
      fill: #000; } }
  .navigation a svg {
    margin-bottom: 5px; }

.neos-content {
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 400;
  color: var(--body-text-color);
  margin: 0; }
  .neos-content a {
    pointer-events: auto;
    text-decoration: underline;
    position: relative;
    overflow: hidden;
    transition: .2s color ease-in-out;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    box-decoration-break: clone; }
    .neos-content a:focus, .neos-content a:active, .neos-content a:active:focus {
      color: var(--primary-color-d15); }
      .neos-content a:focus:after, .neos-content a:active:after, .neos-content a:active:focus:after {
        transform: scaleX(0); }
  .neos-content table {
    border-collapse: collapse;
    border: 1px solid #e9e9e9; }
    @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
      .neos-content table {
        border: 2px solid #000; } }
    .neos-content table th, .neos-content table td {
      padding: 6px 10px;
      scroll-snap-align: start; }
    .neos-content table tr th {
      background: var(--primary-color) !important;
      color: #fff;
      font-size: 0.75rem;
      font-weight: 700;
      text-align: left; }
    .neos-content table tr td {
      font-size: 0.875rem;
      border: 1px solid #e9e9e9; }
  .neos-content figure.table {
    width: 100%;
    overflow: auto;
    margin: var(--section-vertical-gap) 0;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-snap-type: mandatory;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-destination: 0 0; }
  .neos-content blockquote {
    padding: 20px 10px;
    margin: var(--section-vertical-gap) 0; }
    .neos-content blockquote h5 {
      margin: 0 0 10px 0;
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 700; }
    .neos-content blockquote.primary {
      background-color: var(--primary-color-light);
      color: var(--dark-color); }
    .neos-content blockquote.secondary {
      background: var(--divider-color);
      color: #fff; }
  .neos-content h1,
  .neos-content h2,
  .neos-content h3,
  .neos-content h4 {
    color: var(--dark-color);
    line-height: 1.2;
    margin-bottom: 1rem; }
  .neos-content h2 {
    font-size: 1.3125rem; }
  .neos-content h4 {
    font-size: 1rem;
    margin: var(--section-vertical-gap) 0; }
  .neos-content ol,
  .neos-content ul {
    padding: 0 0 0 14px;
    margin: var(--section-vertical-gap) 0; }
  .neos-content ul {
    list-style: none; }
    .neos-content ul li {
      position: relative;
      margin-bottom: 3px; }
    .neos-content ul li:before {
      content: '–';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      height: 1em;
      width: 14px;
      transform: translate(-100%, 0); }
  .neos-content em {
    font-weight: 700; }

:root {
  --divider-height: 8px;
  --negative-divider-height: -8px; }

.article {
  padding: var(--section-vertical-gap) var(--section-horizontal-gap);
  position: relative; }
  .article:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: var(--divider-height);
    background: var(--divider-color); }
  .article:last-child:after {
    display: none; }
  .article__author-and-share {
    position: relative; }
  .article__author {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 20px;
    padding-right: 50px;
    z-index: 3;
    text-decoration: none; }
    .article__author-avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      object-fit: contain; }
    .article__author-name {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 3px 0; }
      .article__author-name span {
        color: var(--primary-color);
        margin: 0 0 2px 0;
        font-size: 0.875rem;
        font-weight: 700; }
      .article__author-name small {
        color: #989898;
        font-size: 0.6875rem; }
  .article__share {
    position: absolute;
    top: 0;
    right: var(--section-horizontal-gap);
    z-index: 4;
    width: 40px;
    height: 40px;
    padding: 10px 14px;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .article__share:focus, .article__share:active, .article__share:active:focus {
      background-color: var(--primary-color-o30); }
    .article__share img {
      display: block;
      width: 100%;
      height: auto; }
  .article__content-image {
    height: auto;
    margin: 0 var(--negative-section-horizontal-gap);
    position: relative;
    z-index: 10;
    pointer-events: none; }
    .article__content-image img {
      width: 100%;
      height: auto; }
  .article__content-slider {
    overflow: hidden;
    height: auto;
    margin: 0 var(--negative-section-horizontal-gap);
    position: relative;
    z-index: 10; }
  .article__content-video {
    position: relative;
    overflow: hidden;
    margin: 0 var(--negative-section-horizontal-gap) 20px;
    z-index: 10; }
    .article__content-video:after {
      content: '';
      position: relative;
      padding-bottom: 56.25%;
      width: 100%;
      height: 0;
      display: block;
      pointer-events: none; }
    .article__content-video iframe {
      position: absolute;
      top: -100%;
      width: 100%;
      height: 300%; }
  .article__link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: var(--divider-height);
    background: transparent;
    outline: none !important;
    -webkit-tap-highlight-color: var(--primary-color-o30);
    z-index: 2; }
  .article__ad {
    background: var(--divider-color);
    padding-top: var(--section-vertical-gap);
    padding-bottom: var(--section-vertical-gap); }
    .article__ad--30x5 .ad {
      width: 300px;
      height: 50px; }
    .article__ad--30x25 .ad {
      width: 300px;
      height: 250px; }
    .article__ad--30x60 .ad {
      width: 300px;
      height: 600px; }
    .article__ad .ad {
      display: block;
      position: relative;
      margin: 0 auto;
      background: #fff; }
      .article__ad .ad span {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2px 7px;
        background: var(--primary-color);
        color: #fff;
        font-size: 0.6875rem; }
      .article__ad .ad img,
      .article__ad .ad video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
      .article__ad .ad a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2; }
  .article + .article__ad {
    margin-top: var(--negative-divider-height); }

.link-box {
  display: block;
  border: 1px solid #ccc;
  position: relative;
  width: 100%;
  height: auto;
  padding: 10px 115px 10px 10px;
  min-height: 70px;
  text-decoration: none;
  pointer-events: auto;
  z-index: 10;
  outline: none !important;
  -webkit-tap-highlight-color: transparent; }
  .link-box:focus, .link-box:active, .link-box:active:focus {
    border-color: var(--primary-color);
    background-color: var(--primary-color-o30); }
    .link-box:focus .link-box__button, .link-box:active .link-box__button, .link-box:active:focus .link-box__button {
      color: #fff;
      background-color: var(--primary-color); }
  .link-box__content small {
    display: block;
    color: #989898;
    font-size: 0.6875rem;
    margin-bottom: 10px; }
  .link-box__content strong {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
    display: block;
    color: var(--dark-color); }
  .link-box__button {
    font-size: 0.8125rem;
    display: block;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    padding: 8px 10px;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    text-decoration: none;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    user-select: none; }

.article__content {
  color: var(--dark-color);
  margin: 0 0 20px;
  pointer-events: none;
  position: relative;
  z-index: 10; }
  .article__content-title {
    font-weight: 700;
    margin: 0 0 10px; }
  .article__content h3.article__content-title {
    font-size: 1rem;
    line-height: 1.3125; }
  .article__content h2.article__content-title {
    font-size: 1.3125rem;
    line-height: 1.2; }
  .article__content h1.article__content-title {
    font-size: 1.5rem;
    line-height: 1.2; }
  .article__content h1.article__content-title {
    font-size: 1.5rem;
    line-height: 1.1666; }

.betterembed {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
  margin: 0;
  max-width: 100%;
  width: 100%;
  padding: var(--section-vertical-gap) var(--section-horizontal-gap);
  color: #989898;
  font-size: .875rem;
  border: 1px solid #ccc; }
  .betterembed__top {
    order: 3; }
  .betterembed__network, .betterembed-switch {
    font-size: 0.6875rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 2px;
    color: var(--body-text-color); }
  .betterembed__header {
    order: 2;
    margin: 0 0 11px !important; }
    .betterembed__header h2 {
      font-size: 1rem;
      line-height: 1.3125;
      font-weight: 700;
      margin: 0; }
  .betterembed__text {
    order: 3;
    font-size: var(--p);
    line-height: 1.4;
    margin-bottom: 15px !important; }
  .betterembed__body {
    background: var(--body-background);
    color: var(--dark-color);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch; }
    .betterembed__body > :last-child {
      padding: 0; }
  .betterembed__body > :not(.betterembed__media) {
    margin: 0; }
  .betterembed__media, .betterembed__media-link {
    order: 4;
    margin: 0; }
  .betterembed__footer {
    display: none;
    /*
    right now we do not want to show the link author and publish date,
    proper styling for this below:
    order: 1;
    border-top: none;
    margin-bottom: 15px !important;

    .betterembed__author {
      font-size: 0.875rem;
      font-weight: 700;
      color: var(--primary-color);
      text-decoration: none !important;
      transition: .2s color ease-in-out;

      &:hover {
        color: var(--primary-color-dark) !important;
      }

      @include active {
        color: var(--primary-color-dark) !important;
      }
    }
    */ }
  .betterembed__read-more {
    float: right;
    font-size: 0.8125rem;
    display: block;
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
    margin-top: 5px;
    padding: 8px 10px;
    border-radius: 2px;
    text-decoration: none !important;
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    user-select: none; }

:root {
  --columns-gap: 10px;
  --double-columns-gap: 20px; }

.article-inner {
  position: relative;
  padding: 0 var(--section-horizontal-gap) var(--section-vertical-gap) var(--section-horizontal-gap); }
  .article-inner .article__content {
    pointer-events: auto; }
  .article-inner .article__content-image img {
    display: block;
    width: 100%;
    height: auto; }
  .article-inner .article__author {
    padding-top: var(--section-vertical-gap); }
  .article-inner .article__share {
    top: var(--section-vertical-gap); }
  .article-inner .article__content-text figure:not(.table) {
    margin: var(--section-vertical-gap) var(--negative-section-horizontal-gap); }
    .article-inner .article__content-text figure:not(.table) figcaption {
      padding: 0 var(--section-horizontal-gap);
      font-size: 0.6875rem;
      color: #989898; }
    .article-inner .article__content-text figure:not(.table) img {
      width: 100%;
      height: auto;
      margin: 0 0 10px 0;
      display: block; }
  .article-inner .article__ad {
    width: auto;
    margin: 0 var(--negative-section-horizontal-gap); }

.link__list {
  list-style: none;
  padding: 0;
  margin: 0; }
  .link__list li {
    display: block;
    width: 100%;
    margin-bottom: 10px; }
    .link__list li:last-child {
      margin-bottom: 0; }
    .link__list li a {
      background-color: var(--header-background);
      color: var(--dark-color);
      display: block;
      font-weight: 700;
      padding: 11px 60px 11px 20px;
      font-size: 1rem;
      line-height: 1.1325;
      text-decoration: none;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      position: relative;
      -webkit-tap-highlight-color: var(--primary-color-o30);
      outline: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
      @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
        .link__list li a {
          box-shadow: 0 0 0 2px #000 inset; } }
      .link__list li a:after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        right: 16px;
        top: 12px;
        transform: rotate(-45deg);
        border-width: 0 1px 1px 0;
        border-style: solid; }

.tile__list {
  padding: 0 var(--section-horizontal-gap) 40px var(--section-horizontal-gap);
  position: relative; }
  .tile__list:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: var(--divider-height);
    background: var(--divider-color); }
  .tile__list:last-child:after {
    display: none; }
  .tile__list-title {
    font-size: 1rem; }
  .tile__list ul {
    padding: 0;
    margin: -5px;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: stretch; }
  .tile__list li {
    display: flex;
    max-width: 50%;
    width: 100%;
    padding: 5px;
    min-height: 110px; }
    .tile__list li .tile__list-item {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      display: flex;
      width: 100%;
      padding: 8px 10px 6px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      color: var(--dark-color);
      text-decoration: none;
      outline: none;
      -webkit-tap-highlight-color: transparent;
      background-color: var(--header-background); }
      @media screen and (-ms-high-contrast: active), screen and (prefers-contrast) {
        .tile__list li .tile__list-item {
          box-shadow: 0 0 0 2px #000 inset; } }
      .tile__list li .tile__list-item:focus, .tile__list li .tile__list-item:active, .tile__list li .tile__list-item:active:focus {
        background: var(--primary-color-o30); }
      .tile__list li .tile__list-item.primary {
        background-color: var(--primary-color-light); }
      .tile__list li .tile__list-item.secondary {
        background-color: var(--grey-light-color); }
      .tile__list li .tile__list-item span {
        font-size: 0.875rem;
        font-weight: 700;
        display: block;
        width: 100%;
        white-space: normal;
        word-break: break-word; }
      .tile__list li .tile__list-item small {
        font-size: 0.6875rem;
        font-weight: 400;
        display: block; }

.contact__list {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto; }
  .contact__list li {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background: var(--header-background);
    margin-bottom: 10px; }
    .contact__list li > a {
      outline: none;
      text-decoration: none;
      position: relative;
      padding: 20px 10px;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start; }
    .contact__list li .avatar {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      margin-right: 10px; }
    .contact__list li .name {
      font-weight: 700;
      font-size: 1rem;
      color: var(--dark-color);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start; }
      .contact__list li .name small {
        font-size: 0.6875rem;
        color: var(--body-text-color) 666;
        display: block; }
    .contact__list li object {
      display: flex;
      margin: 0 0 0 auto; }
      .contact__list li object a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: #e3f1dc;
        border-radius: 100%;
        margin-left: 10px; }
        .contact__list li object a img {
          display: block;
          width: 22px;
          height: auto; }
    .contact__list li sub {
      position: absolute;
      bottom: 5px;
      right: 5px;
      letter-spacing: 1.1px;
      text-transform: uppercase;
      font-weight: 700;
      color: #9b9b9b;
      font-size: 0.6875rem; }

.event__category-links {
  padding: var(--section-vertical-gap) var(--section-horizontal-gap); }
  .event__category-links__filter-by {
    font-size: .75rem;
    color: #989898; }
  .event__category-links__item {
    border-radius: 11px;
    background-color: #FFFFFF;
    margin: 0 .5rem .5rem 0;
    padding: .5rem 1rem;
    color: #666666;
    display: inline-block;
    text-decoration: none !important;
    font-size: 14px; }
    .event__category-links__item--active {
      background-color: #C8E3B8; }

.event {
  padding: var(--section-vertical-gap) var(--section-horizontal-gap);
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start; }
  .event:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: var(--divider-height);
    background: var(--divider-color); }
  .event:last-child:after {
    display: none; }
  .event__list {
    list-style: none;
    padding: 0;
    margin: 0 var(--negative-section-horizontal-gap); }
  .event__date {
    font-weight: 700;
    min-width: 40px;
    width: 40px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    margin-right: 10px; }
    .event__date-month {
      display: block;
      height: auto;
      font-size: 0.75rem;
      line-height: 1;
      padding: 2px 0;
      color: #fff;
      background: #b94e4e; }
    .event__date-day {
      display: block;
      font-size: 1.375rem;
      line-height: 1;
      padding: 4px 0;
      color: #272726; }
  .event__title {
    display: block;
    color: var(--dark-color);
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.1325;
    margin-bottom: 2px; }
  .event__info-date, .event__location {
    display: block;
    color: var(--body-text-color);
    font-size: 0.875rem;
    line-height: 1.5; }
  .event__link {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    display: block;
    opacity: 0;
    z-index: 10;
    -webkit-tap-highlight-color: var(--primary-color-o20); }
  .event__ad {
    background: var(--divider-color);
    padding-top: var(--section-vertical-gap);
    padding-bottom: var(--section-vertical-gap); }
    .event__ad--30x5 .ad {
      width: 300px;
      height: 50px; }
    .event__ad--30x25 .ad {
      width: 300px;
      height: 250px; }
    .event__ad--30x60 .ad {
      width: 300px;
      height: 600px; }
    .event__ad .ad {
      display: block;
      position: relative;
      margin: 0 auto;
      background: #fff; }
      .event__ad .ad span {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2px 7px;
        background: var(--primary-color);
        color: #fff;
        font-size: 0.6875rem; }

.event__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px 0; }
  .event__header .event__info-date {
    flex-grow: 1; }

.event .link-box__button {
  position: relative;
  transform: none;
  margin: auto 0 auto auto;
  display: block;
  right: auto;
  top: auto;
  padding-right: 25px;
  cursor: pointer; }
  @media screen and (max-width: 374px) {
    .event .link-box__button {
      max-width: 110px; } }
  .event .link-box__button:focus, .event .link-box__button:active, .event .link-box__button:active:focus {
    color: var(--primary-color);
    background-color: #fff; }
  .event .link-box__button.active ul {
    max-height: 260px;
    opacity: 1; }
  .event .link-box__button:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 8px;
    width: 4px;
    height: 0;
    border-top: 4px solid var(--primary-color);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent; }
  .event .link-box__button ul {
    display: block;
    padding: 0;
    margin: 11px 0 0 0;
    position: absolute;
    top: 100%;
    right: 0;
    list-style: none;
    background: var(--body-background);
    width: 100%;
    max-height: 0;
    overflow: hidden;
    border-radius: 2px;
    border: 1px solid var(--primary-color);
    min-width: 180px;
    opacity: 0;
    transition: .2s opacity ease-in-out, .2s max-height ease-in-out; }
    .event .link-box__button ul button {
      text-decoration: none;
      display: block;
      line-height: 50px;
      padding-left: 60px;
      color: var(--dark-color);
      border: none;
      width: 100%;
      font-size: 14px;
      text-align: left;
      position: relative;
      border-bottom: 1px solid var(--primary-color-o10);
      cursor: pointer; }
      .event .link-box__button ul button:focus, .event .link-box__button ul button:active, .event .link-box__button ul button:active:focus {
        color: #fff;
        background-color: var(--primary-color); }
      .event .link-box__button ul button svg {
        display: block;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 9px;
        left: 16px; }
      .event .link-box__button ul button[data-type="office365"] svg {
        width: 28px;
        height: 28px;
        top: 11px;
        left: 15px; }
      .event .link-box__button ul button[data-type="outlook"] svg {
        width: 28px;
        height: 28px;
        top: 10px;
        left: 15px; }

.event__body {
  width: 100%; }
  .event__body-location {
    margin: 0 var(--negative-section-horizontal-gap);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 8px var(--section-horizontal-gap);
    background-color: var(--grey-color);
    font-size: 0.875rem;
    line-height: 1.5;
    color: var(--body-text-color); }
    .event__body-location img {
      display: block;
      width: 14px;
      height: auto;
      margin: 0 22px 0 14px; }
    .event__body-location p {
      margin-top: 0;
      margin-bottom: 0; }

.event__content h3 {
  font-size: 1rem;
  line-height: 1.3125;
  color: var(--dark-color); }

.event__content p {
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--body-text-color); }

.event__content i,
.event__content small {
  display: block;
  width: 100%;
  font-size: 0.6875rem;
  line-height: 1.2;
  color: #989898;
  margin: var(--section-vertical-gap) 0; }

[data-type="outlook"] svg .st0 {
  fill: #0A2767; }

[data-type="outlook"] svg .st1 {
  fill: #0364B8; }

[data-type="outlook"] svg .st2 {
  fill: #0078D4; }

[data-type="outlook"] svg .st3 {
  fill: #28A8EA; }

[data-type="outlook"] svg .st4 {
  fill: #14447D; }

[data-type="outlook"] svg .st5 {
  fill: url(#SVGID_1_); }

[data-type="outlook"] svg .st6 {
  opacity: 0.5;
  fill: #0A2767;
  enable-background: new; }

[data-type="outlook"] svg .st7 {
  fill: #1490DF; }

[data-type="outlook"] svg .st8 {
  opacity: 0.1;
  enable-background: new; }

[data-type="outlook"] svg .st9 {
  opacity: 0.05;
  enable-background: new; }

[data-type="outlook"] svg .st10 {
  opacity: 0.2;
  enable-background: new; }

[data-type="outlook"] svg .st11 {
  fill: url(#SVGID_2_); }

[data-type="outlook"] svg .st12 {
  fill: #FFFFFF; }

[data-type="outlook"] svg .st13 {
  fill: #50D9FF; }

.info-block, .info-blocks .cc-revoke,
.info-blocks .cc-window {
  display: block;
  margin: 15px var(--section-horizontal-gap) 10px var(--section-horizontal-gap);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 15px 15px 13px;
  color: var(--dark-color);
  text-decoration: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  background-color: var(--primary-color-light);
  border: none;
  text-align: left;
  font-size: 1rem;
  line-height: 1.3125; }
  .neos-backend .info-block, .neos-backend .info-blocks .cc-revoke, .info-blocks .neos-backend .cc-revoke,
  .neos-backend .info-blocks .cc-window, .info-blocks .neos-backend .cc-window {
    display: none !important; }

.info-block--ios-only {
  display: none; }
  @supports (-webkit-touch-callout: none) {
    .info-block--ios-only {
      /* CSS for iOS devices */ } }

.info-block--notification {
  position: relative;
  color: #fdfefc;
  background-color: #8ec86a;
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='25' viewBox='0 0 11 12.4'%3E%3Cg transform='translate(-581.774 -50.053)'%3E%3Cpath fill='white' d='M583.242,60.883A1.468,1.468,0,0,1,581.8,59.67a1.667,1.667,0,0,1,.412-1.422,2.231,2.231,0,0,0,.633-1.472l-.07-.684a3.966,3.966,0,0,1,7.812-1.377l.253.778a1.856,1.856,0,0,0,.982.938,1.686,1.686,0,0,1,1.07,1.283,1.468,1.468,0,0,1-1.191,1.7l-8.2,1.446A1.508,1.508,0,0,1,583.242,60.883Zm3.451-8.656a3.222,3.222,0,0,0-2.632,1.367,3.184,3.184,0,0,0-.53,2.394l.075.75a2.956,2.956,0,0,1-.829,2.024.906.906,0,0,0-.225.775.7.7,0,0,0,.815.571l8.2-1.446a.7.7,0,0,0,.571-.816.918.918,0,0,0-.584-.7,2.634,2.634,0,0,1-1.422-1.371l-.294-.9a3.2,3.2,0,0,0-3.147-2.648M584.967,52.517a.382.382,0,0,1-.376-.315,2.073,2.073,0,0,1,.205-1.355,1.425,1.425,0,0,1,2.263-.4,2.081,2.081,0,0,1,.656,1.2.382.382,0,0,1-.752.132,1.307,1.307,0,0,0-.407-.761.666.666,0,0,0-1.091.192,1.3,1.3,0,0,0-.122.855.381.381,0,0,1-.31.442A.369.369,0,0,1,584.967,52.517M585.908,62.131c-.5,0-1.284-.269-1.563-1.849a.382.382,0,0,1,.752-.133c.155.884.468,1.29.927,1.209a1.559,1.559,0,0,0,.873-.572,1.3,1.3,0,0,0,.31-1.009.382.382,0,1,1,.752-.132,2.027,2.027,0,0,1-.465,1.616,2.313,2.313,0,0,1-1.338.848A1.351,1.351,0,0,1,585.908,62.131Z'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: auto 30px;
  padding-left: 47px;
  padding-right: 35px; }
  .info-block--notification:after {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%) rotate(-45deg);
    width: 14px;
    height: 14px;
    border-width: 0 1px 1px 0;
    border-style: solid; }
  .info-block--notification:after {
    border-color: white; }

.info-blocks .cc-revoke,
.info-blocks .cc-window {
  position: relative;
  margin: 15px var(--section-horizontal-gap) 10px var(--section-horizontal-gap); }

.install-prompt {
  flex-direction: column;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10090;
  padding: 0 15px;
  background-color: #E3F1DC; }
  .install-prompt h1 {
    font-size: 24px;
    letter-spacing: 0; }
  .install-prompt p {
    margin-top: 0;
    margin-bottom: 14px; }
  .install-prompt__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10091;
    padding: 18px 15px;
    text-decoration: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    border: none;
    font-size: 1rem;
    background: transparent; }
  .install-prompt__arrow-down {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 20px auto;
    animation-name: arrow-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out; }
  .install-prompt--is-hidden {
    display: none; }

@keyframes arrow-animation {
  0% {
    transform: translateY(5px); }
  50% {
    transform: translateY(-3px); }
  to {
    transform: translateY(5px); } }

.person {
  padding-bottom: 0; }
  .person__avatar {
    display: block;
    width: 111px;
    height: 111px;
    border-radius: 60px;
    margin: var(--section-vertical-gap) auto; }
  .person__name {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--dark-color);
    line-height: 1.1666;
    text-align: center;
    margin: 0; }
  .person__info {
    font-weight: 400;
    font-size: 1rem;
    color: var(--body-text-color);
    line-height: 1.3125;
    text-align: center;
    margin: 0; }
    .person__info sub {
      display: block;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.125;
      color: #9b9b9b; }
  .person__contact-list {
    list-style: none;
    padding: 20px 13px;
    background: var(--header-background);
    margin: var(--section-vertical-gap) var(--negative-section-horizontal-gap) 0 var(--negative-section-horizontal-gap); }
    .person__contact-list li {
      display: block;
      width: 100%; }
      .person__contact-list li + li {
        margin: 10px 0 0 0; }
    .person__contact-list-item {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: 0.875rem;
      line-height: 1.1325;
      color: var(--body-text-color);
      text-decoration: none; }
      .person__contact-list-item .icon {
        width: 36px;
        height: 36px;
        background-color: #e3f1dc;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 10px 0 0; }
      .person__contact-list-item img {
        width: 22px; }
  .person__description h4 {
    margin-bottom: 0; }

.search__number-of-entries {
  margin: 20px var(--section-horizontal-gap) 0; }

.search__form {
  margin: 20px var(--section-horizontal-gap) 0; }
  .search__form input[type='search'] {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid var(--secondary-color);
    border-radius: 4px; }

.search__item {
  position: relative;
  padding: calc(40px - var(--divider-height)) 0 40px 4px; }
  .search__item-parents .breadcrumbs {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    white-space: nowrap;
    padding: 0 !important;
    margin: 0 !important; }
  .search__item-parents .breadcrumbs--ellipsis:after {
    content: '...';
    position: absolute;
    right: 0;
    height: 1.25em;
    background: linear-gradient(to right, transparent 0%, var(--body-background) 80%);
    color: #4d4d4d;
    padding-left: 30px; }
  .search__item h3 {
    margin-top: 0;
    margin-bottom: .5rem; }
  .search__item h3 svg {
    vertical-align: bottom;
    margin-right: 10px;
    fill: var(--primary-color); }
  .search__item p {
    margin-top: .5rem; }
  .search__item:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: var(--divider-height);
    background: var(--divider-color); }
  .search__item em {
    background-color: yellow; }

.header__search-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: .3s opacity ease-in-out; }
  .header__search-modal .container {
    background: #fff;
    box-shadow: 0 0 0 100vh rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.2); }
  .header__search-modal--active {
    opacity: 1;
    pointer-events: auto; }
  .header__search-modal .search__form {
    margin: 0;
    padding: 17px var(--section-horizontal-gap); }

.search__form__suggestions {
  z-index: 999;
  background: #fcfcfc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid var(--secondary-color);
  border-top: none;
  margin-top: -4px;
  list-style: none;
  padding: 4px 0 0 0;
  overflow: hidden; }
  .search__form__suggestions:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: #fff; }
  .search__form__suggestions li {
    padding: 12px;
    cursor: pointer;
    user-select: none;
    transition: .2s background ease-in-out, .2s color ease-in-out; }
    .search__form__suggestions li:hover, .search__form__suggestions li:active, .search__form__suggestions li:focus {
      background: var(--primary-color-light); }
  .search__form__suggestions .selected {
    color: #fff;
    background: var(--primary-color); }
    .search__form__suggestions .selected:hover, .search__form__suggestions .selected:active, .search__form__suggestions .selected:focus {
      background: var(--primary-color-light); }

.notifications .icon-bell {
  width: 12px;
  height: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgd2lkdGg9IjIyIiBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMTEgMTIuNCI+CiAgPGcgZmlsbD0iIzE2MTYxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU4MS43NzQgLTUwLjA1MykiPgogICAgPHBhdGgKICAgICAgZD0iTTU4My4yNDIsNjAuODgzQTEuNDY4LDEuNDY4LDAsMCwxLDU4MS44LDU5LjY3YTEuNjY3LDEuNjY3LDAsMCwxLC40MTItMS40MjIsMi4yMzEsMi4yMzEsMCwwLDAsLjYzMy0xLjQ3MmwtLjA3LS42ODRhMy45NjYsMy45NjYsMCwwLDEsNy44MTItMS4zNzdsLjI1My43NzhhMS44NTYsMS44NTYsMCwwLDAsLjk4Mi45MzgsMS42ODYsMS42ODYsMCwwLDEsMS4wNywxLjI4MywxLjQ2OCwxLjQ2OCwwLDAsMS0xLjE5MSwxLjdsLTguMiwxLjQ0NkExLjUwOCwxLjUwOCwwLDAsMSw1ODMuMjQyLDYwLjg4M1ptMy40NTEtOC42NTZhMy4yMjIsMy4yMjIsMCwwLDAtMi42MzIsMS4zNjcsMy4xODQsMy4xODQsMCwwLDAtLjUzLDIuMzk0bC4wNzUuNzVhMi45NTYsMi45NTYsMCwwLDEtLjgyOSwyLjAyNC45MDYuOTA2LDAsMCwwLS4yMjUuNzc1LjcuNywwLDAsMCwuODE1LjU3MWw4LjItMS40NDZhLjcuNywwLDAsMCwuNTcxLS44MTYuOTE4LjkxOCwwLDAsMC0uNTg0LS43LDIuNjM0LDIuNjM0LDAsMCwxLTEuNDIyLTEuMzcxbC0uMjk0LS45YTMuMiwzLjIsMCwwLDAtMy4xNDctMi42NDhNNTg0Ljk2Nyw1Mi41MTdhLjM4Mi4zODIsMCwwLDEtLjM3Ni0uMzE1LDIuMDczLDIuMDczLDAsMCwxLC4yMDUtMS4zNTUsMS40MjUsMS40MjUsMCwwLDEsMi4yNjMtLjQsMi4wODEsMi4wODEsMCwwLDEsLjY1NiwxLjIuMzgyLjM4MiwwLDAsMS0uNzUyLjEzMiwxLjMwNywxLjMwNywwLDAsMC0uNDA3LS43NjEuNjY2LjY2NiwwLDAsMC0xLjA5MS4xOTIsMS4zLDEuMywwLDAsMC0uMTIyLjg1NS4zODEuMzgxLDAsMCwxLS4zMS40NDJBLjM2OS4zNjksMCwwLDEsNTg0Ljk2Nyw1Mi41MTdNNTg1LjkwOCw2Mi4xMzFjLS41LDAtMS4yODQtLjI2OS0xLjU2My0xLjg0OWEuMzgyLjM4MiwwLDAsMSwuNzUyLS4xMzNjLjE1NS44ODQuNDY4LDEuMjkuOTI3LDEuMjA5YTEuNTU5LDEuNTU5LDAsMCwwLC44NzMtLjU3MiwxLjMsMS4zLDAsMCwwLC4zMS0xLjAwOS4zODIuMzgyLDAsMSwxLC43NTItLjEzMiwyLjAyNywyLjAyNywwLDAsMS0uNDY1LDEuNjE2LDIuMzEzLDIuMzEzLDAsMCwxLTEuMzM4Ljg0OEExLjM1MSwxLjM1MSwwLDAsMSw1ODUuOTA4LDYyLjEzMVoiLz4KICA8L2c+Cjwvc3ZnPgo=");
  margin-right: 4px; }

.notifications li.active {
  background-color: var(--body-background);
  color: var(--primary-color); }
  .notifications li.active .icon-bell {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDIyIDI1Ij4KICA8cGF0aAogICAgZmlsbD0iIzc1Yjk0ZSIKICAgIGQ9Ik0yLjg5IDIxLjc4YTIuODg5IDIuODg5IDAgMCAxLTIuODM5LTIuMzg0IDMuMjc3IDMuMjc3IDAgMCAxIC44MS0yLjc5NGMuNTY4LS42MjQgMS4yNDUtMS42MzIgMS4yNDQtMi44OTJsLS4xMzctMS4zNDRDMS4yMyA4LjE4OCA0LjA2NSA0LjEzNiA4LjMgMy4zOWM0LjIxMy0uNzQzIDguMjUxIDIuMDY2IDkuMDIyIDYuMjdsLjQ5NyAxLjUyN2MuNDE4Ljg1NSAxLjExNiAxLjU0MyAxLjkyOCAxLjg0NWEzLjMxNSAzLjMxNSAwIDAgMSAyLjEwNCAyLjUyMSAyLjg3IDIuODcgMCAwIDEtLjQ3OSAyLjE1NSAyLjg2NCAyLjg2NCAwIDAgMS0xLjg2IDEuMTg2TDMuMzkxIDIxLjczNmEyLjk1MyAyLjk1MyAwIDAgMS0uNTAyLjA0NE02LjI3NCA1LjM0YS43NS43NSAwIDAgMS0uNzM4LS42MmMtLjE2Ni0uOTQyLS4wMjMtMS44ODguNDAyLTIuNjYzQzYuMzk0IDEuMjI4IDcuMTEzLjY5IDcuOTY0LjU0YzEuNjk3LS4yOTQgMy4zNTcgMS4wOTIgMy43MTIgMy4xYS43NS43NSAwIDAgMS0xLjQ3OC4yNmMtLjIwNy0xLjE3My0xLjEwNS0yLjAzMy0xLjk3My0xLjg4My0uMzk1LjA3LS43NC4zNDEtLjk3Mi43NjMtLjI2LjQ3Ny0uMzQ3IDEuMDczLS4yMzkgMS42OGEuNzUxLjc1MSAwIDAgMS0uNzQuODgxTTUuNjY4IDIyLjg1NmMuNzEgMS4zODcgMS43MzMgMS42NzcgMi40NTcgMS42NzcuMTg2IDAgLjM1Mi0uMDE5LjQ4OC0uMDQzIDEuMzA2LS4yMyAyLjYzLTEuMzM2IDMuMjM4LTIuNzI0eiIvPgo8L3N2Zz4K"); }
  .notifications li.active .notification__header,
  .notifications li.active .notification__body {
    color: var(--primary-color); }

.notifications li {
  color: var(--body-text-color);
  position: relative; }
  .notifications li:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: var(--divider-color); }

.notification {
  display: block;
  position: relative;
  padding: var(--section-horizontal-gap) 50px var(--section-horizontal-gap) var(--section-horizontal-gap);
  -webkit-tap-highlight-color: var(--primary-color-o20);
  text-decoration: none; }
  .notification.notification--has-link {
    position: relative;
    text-decoration: none;
    display: block; }
    .notification.notification--has-link:after {
      content: '';
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(0, -50%) rotate(-45deg);
      width: 14px;
      height: 14px;
      border-width: 0 1px 1px 0;
      border-style: solid; }
  .notification__list {
    list-style: none;
    padding: 0;
    margin: 0 var(--negative-section-horizontal-gap); }
  .notification__header {
    font-size: 0.6875rem;
    color: #989898;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start; }
  .notification__body {
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--body-text-color);
    line-height: 1.25; }

.codeq-survey-form {
  justify-content: flex-end; }

.codeq-survey-form__rating-label {
  /* should be clickable with a thumb */
  font-size: 2rem;
  margin-right: 0.5rem; }

.splash {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNzUiIGhlaWdodD0iNjYzIiB2aWV3Qm94PSIwIDAgNzc3IDY2NiI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyMDAgLTM1NSkgc2NhbGUoMyAyLjA3KSI+CiAgICA8cGF0aCBmaWxsPSIjYzhlM2I4IiBkPSJNMzcuNDEgNjY1LjI2aDY2NS42ODdWMEgzNy40MTF6Ii8+CiAgICA8cGF0aCBmaWxsPSIjYWNkNTk1IiBkPSJNNzM5LjI4MyA2NjUuMjYxSDczLjU5NnYtNDc3LjIybDY2NS42ODctODEuODkyeiIvPgogICAgPHBhdGggZmlsbD0iIzkxYzc3MSIgZD0iTTY2NS42ODcgNjY1LjI2MUgwVjI1OC43NzVsNjY1LjY4NyAyOS43ODh6Ii8+CiAgICA8cGF0aCBmaWxsPSIjNzViOTRlIiBkPSJNNzc2LjA4IDY2NS4yNjFIMTEwLjM5NVY1MTUuNjYzTDc3Ni4wOCAzMzIuNjN6Ii8+CiAgPC9nPgo8L3N2Zz4K");
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  padding: 44px 72px;
  pointer-events: none;
  overflow: hidden; }
  .splash__logo {
    display: block;
    width: 100%;
    max-width: 222px;
    height: auto;
    margin-bottom: 44px; }
  .splash p {
    color: #fff;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0; }

.Pagination, .neos-widget-paginator {
  width: 100%;
  padding: 15px var(--section-horizontal-gap) 25px;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  list-style: none;
  user-select: none; }
  .Pagination li, .neos-widget-paginator li {
    margin-left: 5px; }
    .Pagination li:first-child, .neos-widget-paginator li:first-child {
      margin-left: 0; }
    .Pagination li.isCurrent a, .Pagination li.current, .neos-widget-paginator li.isCurrent a, .neos-widget-paginator li.current {
      background: var(--primary-color);
      color: var(--header-background); }
    .Pagination li.previous a,
    .Pagination li.next a, .neos-widget-paginator li.previous a,
    .neos-widget-paginator li.next a {
      position: relative;
      text-indent: -99999px; }
      .Pagination li.previous a:after,
      .Pagination li.next a:after, .neos-widget-paginator li.previous a:after,
      .neos-widget-paginator li.next a:after {
        content: "\2190";
        position: absolute;
        top: 0;
        left: 0;
        line-height: 40px;
        width: 40px;
        text-align: center;
        color: var(--primary-color-d15);
        text-indent: 0; }
    .Pagination li.next a:after, .neos-widget-paginator li.next a:after {
      content: "\2192"; }
  .Pagination a,
  .Pagination .current, .neos-widget-paginator a,
  .neos-widget-paginator .current {
    text-decoration: none;
    display: block;
    background: var(--primary-color-light);
    min-width: 40px;
    height: 40px;
    font-size: 1.125rem;
    color: var(--primary-color-d15);
    text-align: center;
    line-height: 40px;
    transition: .2s background-color ease-in-out, .2s color ease-in-out;
    padding: 0 5px; }
    .Pagination a:focus, .Pagination a:active, .Pagination a:active:focus,
    .Pagination .current:focus,
    .Pagination .current:active,
    .Pagination .current:active:focus, .neos-widget-paginator a:focus, .neos-widget-paginator a:active, .neos-widget-paginator a:active:focus,
    .neos-widget-paginator .current:focus,
    .neos-widget-paginator .current:active,
    .neos-widget-paginator .current:active:focus {
      background: var(--primary-color);
      color: var(--header-background); }

.breadcrumbs {
  padding: 20px 0 0 0;
  margin: 0 var(--section-horizontal-gap) 2px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  z-index: 20; }
  .breadcrumbs li + li:before {
    content: '/';
    margin-right: 2px;
    margin-left: 2px;
    vertical-align: middle;
    font-size: 0.8125rem; }
  .breadcrumbs span, .breadcrumbs a {
    display: inline-block;
    font-size: 0.6875rem;
    font-weight: 700;
    line-height: 1;
    color: var(--body-text-color);
    text-decoration: none; }
  .breadcrumbs a:focus, .breadcrumbs a:active, .breadcrumbs a:active:focus {
    color: var(--primary-color); }
