.Pagination, .neos-widget-paginator {
  width: 100%;
  padding: 15px var(--section-horizontal-gap) 25px;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  list-style: none;
  user-select: none;

  li {
    margin-left: 5px;

    &:first-child{
      margin-left: 0;
    }

    &.isCurrent a,
    &.current {
      background: var(--primary-color);
      color: var(--header-background);
    }

    &.previous a,
    &.next a {
      position: relative;
      text-indent: -99999px;

      &:after {
        content: "\2190";
        position: absolute;
        top: 0;
        left: 0;
        line-height: 40px;
        width: 40px;
        text-align: center;
        color: var(--primary-color-d15);
        text-indent: 0;
      }
    }

    &.next a:after {
      content: "\2192";
    }
  }

  a,
  .current {
    text-decoration: none;
    display: block;
    background: var(--primary-color-light);
    min-width: 40px;
    height: 40px;
    font-size: rem(18);
    color: var(--primary-color-d15);
    text-align: center;
    line-height: 40px;
    transition: .2s background-color ease-in-out, .2s color ease-in-out;
    padding: 0 5px;

    @include active {
      background: var(--primary-color);
      color: var(--header-background);
    }
  }
}
