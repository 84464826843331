.person {
  padding-bottom: 0;

  &__avatar {
    display: block;
    width: 111px;
    height: 111px;
    border-radius: 60px;
    margin: var(--section-vertical-gap) auto;
  }

  &__name {
    font-size: rem(24);
    font-weight: 500;
    color: var(--dark-color);
    line-height: 1.1666;
    text-align: center;
    margin: 0;
  }

  &__info {
    font-weight: 400;
    font-size: rem(16);
    color: var(--body-text-color);
    line-height: 1.3125;
    text-align: center;
    margin: 0;

    sub {
      display: block;
      font-weight: 700;
      font-size: rem(14);
      line-height: 1.125;
      color: #9b9b9b;
    }
  }

  &__contact-list {
    list-style: none;
    padding: 20px 13px;
    background: var(--header-background);
    margin: var(--section-vertical-gap) var(--negative-section-horizontal-gap) 0 var(--negative-section-horizontal-gap);

    li {
      display: block;
      width: 100%;

      & + li {
        margin: 10px 0 0 0;
      }
    }

    &-item {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      font-size: rem(14);
      line-height: 1.1325;
      color: var(--body-text-color);
      text-decoration: none;

      .icon {
        width: 36px;
        height: 36px;
        background-color: #e3f1dc;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 10px 0 0;
      }

      img {
        width: 22px;
      }
    }
  }

  &__description {
    h4 {
      margin-bottom: 0;
    }
  }
}
