.gallery {
  margin: 0 0 var(--section-vertical-gap) 0;
  column-count: 2;
  column-fill: balance;
  column-rule-width: 0;
  column-width: calc(50vw - var(--double-columns-gap));
  column-gap: var(--columns-gap);

  @media screen and (min-width: 600px) {
    column-width: 283px;
  }

  a {
    display: block;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    padding: 0;
    margin-bottom: var(--columns-gap);

    &:after {
      display: none !important;
    }

    @supports (-webkit-touch-callout: none) {
      &:first-child {
        margin-top: var(--columns-gap);
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
