.link__list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: block;
    width: 100%;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      background-color: var(--header-background);
      color: var(--dark-color);
      display: block;
      font-weight: 700;
      padding: 11px 60px 11px 20px;
      font-size: rem(16);
      line-height: 1.1325;
      text-decoration: none;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      position: relative;
      -webkit-tap-highlight-color: var(--primary-color-o30);
      outline: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include high-contrast {
        box-shadow: 0 0 0 2px $black inset;
      }

      &:after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        right: 16px;
        top: 12px;
        transform: rotate(-45deg);
        border-width: 0 1px 1px 0;
        border-style: solid;
      }
    }
  }
}
