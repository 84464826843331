.event {
  &__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 20px 0;

    .event__info-date {
      flex-grow: 1;
    }
  }

  .link-box__button {
    position: relative;
    transform: none;
    margin: auto 0 auto auto;
    display: block;
    right: auto;
    top: auto;
    padding-right: 25px;
    cursor: pointer;

    @media screen and (max-width: 374px) {
      max-width: 110px;
    }

    @include active {
      color: var(--primary-color);
      background-color: $white;
    }

    &.active ul {
      max-height: 260px;
      opacity: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 15px;
      right: 8px;
      width: 4px;
      height: 0;
      border-top: 4px solid var(--primary-color);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }

    ul {
      display: block;
      padding: 0;
      margin: 11px 0 0 0;
      position: absolute;
      top: 100%;
      right: 0;
      list-style: none;
      background: var(--body-background);
      width: 100%;
      max-height: 0;
      overflow: hidden;
      border-radius: 2px;
      border: 1px solid var(--primary-color);
      min-width: 180px;
      opacity: 0;
      transition: .2s opacity ease-in-out, .2s max-height ease-in-out;

      button {
        text-decoration: none;
        display: block;
        line-height: 50px;
        padding-left: 60px;
        color: var(--dark-color);
        border: none;
        width: 100%;
        font-size: 14px;
        text-align: left;
        position: relative;
        border-bottom: 1px solid var(--primary-color-o10);
        cursor: pointer;

        @include active {
          color: $white;
          background-color: var(--primary-color);
        }

        svg {
          display: block;
          position: absolute;
          width: 30px;
          height: 30px;
          top: 9px;
          left: 16px;
        }

        &[data-type="office365"] svg {
          width: 28px;
          height: 28px;
          top: 11px;
          left: 15px;
        }

        &[data-type="outlook"] svg {
          width: 28px;
          height: 28px;
          top: 10px;
          left: 15px;
        }
      }
    }
  }

  &__body {
    width: 100%;

    &-location {
      margin: 0 var(--negative-section-horizontal-gap);
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      padding: 8px var(--section-horizontal-gap);
      background-color: var(--grey-color);
      font-size: rem(14);
      line-height: 1.5;
      color: var(--body-text-color);

      img {
        display: block;
        width: 14px;
        height: auto;
        margin: 0 22px 0 14px;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h3 {
      font-size: rem(16);
      line-height: 1.3125;
      color: var(--dark-color);
    }

    p {
      font-size: rem(14);
      line-height: 1.5;
      color: var(--body-text-color);
    }

    i,
    small {
      display: block;
      width: 100%;
      font-size: rem(11);
      line-height: 1.2;
      color: #989898;
      margin: var(--section-vertical-gap) 0;
    }
  }
}


[data-type="outlook"] svg {
  .st0 {
    fill: #0A2767;
  }

  .st1 {
    fill: #0364B8;
  }

  .st2 {
    fill: #0078D4;
  }

  .st3 {
    fill: #28A8EA;
  }

  .st4 {
    fill: #14447D;
  }

  .st5 {
    fill: url(#SVGID_1_);
  }

  .st6 {
    opacity: 0.5;
    fill: #0A2767;
    enable-background: new;
  }

  .st7 {
    fill: #1490DF;
  }

  .st8 {
    opacity: 0.1;
    enable-background: new;
  }

  .st9 {
    opacity: 0.05;
    enable-background: new;
  }

  .st10 {
    opacity: 0.2;
    enable-background: new;
  }

  .st11 {
    fill: url(#SVGID_2_);
  }

  .st12 {
    fill: #FFFFFF;
  }

  .st13 {
    fill: #50D9FF;
  }
}
