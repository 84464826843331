
body.body--is-closing {
  .install-prompt,
  .header__search-modal {
    display: none;
  }

  main {
    // only hide, no display:none to keep the scroll position when going back
    pointer-events: none;
    opacity: 0;
  }

  // animation from https://loading.io/css/
  position: relative;

  &:before,
  &:after {
    content: '';
    position: fixed;
    z-index: 9999;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  &:after {
    animation-delay: -0.5s;
  }
}

@keyframes lds-ripple {
  0% {
    top: calc(50%);
    left: calc(50%);
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    opacity: 0;
  }
}
