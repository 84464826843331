.event__category-links {
  padding: var(--section-vertical-gap) var(--section-horizontal-gap);

  &__filter-by {
    font-size: .75rem;
    color: #989898;
  }

  &__item {
    border-radius: 11px;
    background-color: #FFFFFF;
    margin: 0 .5rem .5rem 0;
    padding: .5rem 1rem;
    color: #666666;
    display: inline-block;
    text-decoration: none !important;
    font-size: 14px;

    &--active {
      background-color: #C8E3B8;
    }
  }
}
