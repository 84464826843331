.voucher-page {
    &__btn {
        font-family: 'DM Sans', sans-serif;
        border: 0 none;
        background: var(--primary-color);
        color: var(--header-background);
        font-size: 1.25rem;
        padding: .5rem;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
        transition: all .3s ease;

        &[disabled] {
            background: var(--primary-color-light);
            box-shadow: 0 0 0 0 !important;
        }
    }

    &__btn-cart {
        position: fixed;
        right: 0;
        bottom: 70px;
        max-width: 0;
        max-height: 0;
        opacity: 0;
        transition: all .5s ease;
        z-index: 999999;

        &--visible {
            max-width: 200px;
            max-height: 200px;
            opacity: 1;
        }
    }

    &__overview {
        background-color: #E9E9E9;

        h2 {
            font-size: 24px;
        }

        &-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: .5rem;
            background-color: #DDDDDD;
            margin-bottom: 3px;
        }

        &-list {
            &__item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: .5rem;
            }
        }
    }

    &__formContainer {
        background-color: #E9E9E9;
        padding-top: 0;
        padding-bottom: var(--section-vertical-gap);
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: all .3s ease;

        &--is-visible {
            max-height: 1000px;
            opacity: 1;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;

        input[type='text'],
        input[type='email'],
        input[type='date'],
        select {
            font-family: 'DM Sans', sans-serif;
            padding: .5rem;
            margin-bottom: .25rem;
            font-size: 16px;
        }

        input[type='date'] {
            width: 100%;
        }

        select {
            background-color: #fff;
            padding: 0.375rem 0.5rem;
            border: none;
            outline: none !important;
        }

        ::placeholder {
            color: #272726 !important;
        }

        ::-webkit-input-placeholder {
            color: #272726 !important;
        }

        &-submitButton {
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.voucher-item {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &__content {
        &-text {
            padding-bottom: 1rem;
        }

        & figure > img {
            max-width: 100%;
        }
    }

    &__variants-item, &__sum {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: .5rem;
    }

    &__sum {
        font-weight: bold;
        color: #666666;
        transition: all .3s ease;
        max-height: 0;
        overflow: hidden;
        opacity: 0;
    }

    &--selected &__sum {
        max-height: 100px;
        opacity: 1;
    }

    &__variants {
        &-item {
            color: #272726;
            background-color: #E9E9E9;
            margin-bottom: 3px;
            transition: background-color .3s ease;

            &--selected {
                background-color: #CCCCCC;
            }
        }

        &-item-form-inputField {
            height: 2rem;
            margin-left: -1rem;
            margin-right: -1rem;
            padding: 0 1rem;
            text-align: center;
            vertical-align: bottom;
            width: 75px;
            font-size: 14px;
            // Hide number increase/decrease arrows in Firefox
            -moz-appearance: textfield;

            // Hide number increase/decrease arrows in other browsers
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        &-item-form-increaseBtn, &-item-form-decreaseBtn {
            height: 2rem;
            width: 2rem;
            border-radius: 7px;
            padding: 0 !important;
            z-index: 10;
            position: relative;
        }
    }
}
