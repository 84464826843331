@function rem($font-size) {
  $base: 16;
  @return #{$font-size / $base}rem;
}

@mixin iterate($direction: left, $count: 1, $step: .05, $initial-delay: 0) {
  @if ($direction == center) {
    $center: round($count / 2);

    @if ($center == floor($count / 2)) {
      $center1: $center;
      $center2: $center + 1;

      @for $i from 1 through $center - 1 {
        &:nth-child(#{$i}) {
          transition-delay: #{($center - $i + 1) * $step + $initial-delay}s;
        }

        @if ($i < $center) {
          &:nth-child(#{$count - $i + 1}) {
            transition-delay: #{($center - $i + 1) * $step + $initial-delay}s;
          }
        }
      }

      @for $i from $center1 through $center2 {
        &:nth-child(#{$i}) {
          transition-delay: #{$step + $initial-delay}s;
        }
      }
    } @else {
      @for $i from 1 through $center - 1 {
        $time: #{($i+1) * $step + $initial-delay}s;
        $iterator1: $center - $i;
        $iterator2: $center + $i;

        &:nth-child(#{$iterator1}) {
          transition-delay: $time;
        }

        &:nth-child(#{$iterator2}) {
          transition-delay: $time;
        }
      }

      &:nth-child(#{$center}) {
        transition-delay: #{$step + $initial-delay}s;
      }
    }
  }

  @for $i from 1 through $count {
    $time: #{$i * $step + $initial-delay}s;

    @if ($direction == start) {
      &:nth-child(#{$i}) {
        transition-delay: $time;
      }
    }

    @if ($direction == end) {
      $iterator: $count - $i + 1;

      &:nth-child(#{$iterator}) {
        transition-delay: $time;
      }
    }
  }
}

@mixin rect($size, $position: 'relative', $before: true) {
  $rect-sizes: (
    '1x1': 100,
    '1x2': 50,
    '3x4': 75,
    '9x16': 56.25,
  );

  @if ($before != true) {
    $pseudo: after;
  }

  $pseudo: before !default;

  @if (map-has-key($rect-sizes, $size)) {
    position: $position;

    &:#{$pseudo} {
      content: '';
      position: relative;
      display: block;
      width: 100%;
      pointer-events: none;
      padding-bottom: map-get($rect-sizes, $size);
    }

    &-inner {
      top: 0;
      left: 0;
      @extend .fs !optional;
    }
  } @else {
    @error "Size is not specified";
  }
}

@mixin selection($bc, $c) {
  *::-moz-selection {
    background: $bc;
    color: $c;
  }
  *::selection {
    background: $bc;
    color: $c;
  }
}

@mixin active {
  &:focus, &:active, &:active:focus {
    @content;
  }
}

@mixin link-icon-right {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%) rotate(-45deg);
    width: 14px;
    height: 14px;
    border-width: 0 1px 1px 0;
    border-style: solid;
  }
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fs {
  position: absolute;
  width: 100%;
  height: 100%;
}

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.color-dark {
  color: var(--dark-color) !important;
}
