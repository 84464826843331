.event {
  padding: var(--section-vertical-gap) var(--section-horizontal-gap);
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: var(--divider-height);
    background: var(--divider-color);
  }

  &:last-child:after {
    display: none;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 var(--negative-section-horizontal-gap);
  }

  &__date {
    font-weight: 700;
    min-width: 40px;
    width: 40px;
    text-align: center;
    background-color: $white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
    margin-right: 10px;

    &-month {
      display: block;
      height: auto;
      font-size: rem(12);
      line-height: 1;
      padding: 2px 0;
      color: $white;
      background: $danger;
    }

    &-day {
      display: block;
      font-size: rem(22);
      line-height: 1;
      padding: 4px 0;
      color: #272726;
    }
  }

  &__title {
    display: block;
    color: var(--dark-color);
    font-size: rem(16);
    font-weight: 700;
    line-height: 1.1325;
    margin-bottom: 2px;
  }

  &__info-date,
  &__location {
    display: block;
    color: var(--body-text-color);
    font-size: rem(14);
    line-height: 1.5;
  }

  &__link {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
    display: block;
    opacity: 0;
    z-index: 10;
    -webkit-tap-highlight-color: var(--primary-color-o20);
  }

  &__ad {
    background: var(--divider-color);
    padding-top: var(--section-vertical-gap);
    padding-bottom: var(--section-vertical-gap);

    &--30x5 .ad {
      width: 300px;
      height: 50px;
    }

    &--30x25 .ad {
      width: 300px;
      height: 250px;
    }

    &--30x60 .ad {
      width: 300px;
      height: 600px;
    }

    .ad {
      display: block;
      position: relative;
      margin: 0 auto;
      background: $white;

      span {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2px 7px;
        background: var(--primary-color);
        color: $white;
        font-size: rem(11);
      }
    }
  }
}
